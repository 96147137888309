define('ember-material-lite/components/mdl-tooltip', ['exports', 'ember-material-lite/components/-base-toplevel-component', 'ember-material-lite/templates/components/mdl-tooltip'], function (exports, _baseToplevelComponent, _mdlTooltip) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseToplevelComponent.default.extend({
    primaryClassName: 'tooltip',
    layout: _mdlTooltip.default,
    isLarge: false,
    attributeBindings: ['for'],
    classNameBindings: ['isLarge:mdl-tooltip--large'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var mdlTooltip = new window.MaterialTooltip(this.get('element'));
      this.set('_mdlComponent', mdlTooltip);
    }
  });
});