define('ember-sauce-material-design/components/smd-form', ['exports', 'ember-sauce-material-design/templates/components/smd-form'], function (exports, _smdForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // Services
    toaster: Ember.inject.service('smd-toaster'),
    // Attributes
    layout: _smdForm.default,
    tagName: 'form',
    model: null,
    errorMessage: 'Please correct the errors in the form',
    // Computed
    childFormControls: Ember.computed('childViews', function () {

      var findChildFormControls = function findChildFormControls(thisComponent) {

        var childViews = thisComponent.get('childViews');
        var childFormControls = childViews.filter(function (childView) {
          return childView.element.className.match('smd-form__control') || childView.constructor.toString().indexOf('smd-form-control') !== -1;
        });

        childViews.forEach(function (childView) {
          childFormControls.addObjects(findChildFormControls(childView));
        });

        return childFormControls;
      };

      return findChildFormControls(this);
    }),
    validate: function validate() {
      var errorMessages = [];

      var formControls = this.get('childFormControls');

      formControls.forEach(function (formControl) {
        if (formControl.get('validation') && !formControl.get('validation.isValid')) {
          errorMessages.addObjects(formControl.get('validation.errors').mapBy('message'));
        }
        formControl.set('didValidate', true);
      });

      if (errorMessages.length !== 0) {
        var errorMessage = errorMessages.join(', ');

        this.get('toaster').setMessage(this.get('errorMessage'));

        Ember.Logger.log("Errors: " + errorMessage);

        return false;
      }
      return true;
    },
    // Events
    submit: function submit(e) {
      e.preventDefault();

      if (this.validate()) {
        this.sendAction('action', this.get('model'));
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    }
  });
});