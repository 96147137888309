define('ember-material-lite/components/mdl-nav', ['exports', 'ember-composability/mixins/parent-component-support', 'ember-material-lite/components/-base-toplevel-component', 'ember-material-lite/templates/components/mdl-nav'], function (exports, _parentComponentSupport, _baseToplevelComponent, _mdlNav) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var A = Ember.A,
      computed = Ember.computed,
      oneWay = Ember.computed.oneWay,
      next = Ember.run.next;
  exports.default = _baseToplevelComponent.default.extend(_parentComponentSupport.default, {
    primaryClassName: 'layout',
    composableChildrenDebounceTime: 1,
    classNameBindings: ['fixedHeader:mdl-layout--fixed-header:mdl-layout--overlay-drawer-button', 'fixedDrawer:mdl-layout--fixed-drawer'],
    layout: _mdlNav.default,
    title: null,
    mobileTitle: oneWay('title'),
    fixedHeader: false,
    fixedDrawer: false,
    transparent: false,
    waterfallMenu: false,
    scrollHeader: false,
    includeHeader: true,
    includeHeaderLinks: true,
    includeDrawer: true,
    includeDrawerTitle: true,
    _mdlComponent: null,
    closeDrawerOnItemClick: false,

    _drawerNavItems: computed('composableChildren.[]', 'composableChildren.@each.inDrawer', function () {
      return A(this.get('composableChildren').filter(function (x) {
        return x.inDrawer;
      }));
    }),

    _headerNavItems: computed('composableChildren.[]', 'composableChildren.@each.inHeader', function () {
      return A(this.get('composableChildren').filter(function (x) {
        return x.inHeader;
      }));
    }),

    _headerClassString: computed('waterfallMenu', function () {
      var classes = ['mdl-layout__header'];
      if (this.get('waterfallMenu')) {
        classes.push('mdl-layout__header--waterfall');
      }
      if (this.get('scrollHeader')) {
        classes.push('mdl-layout__header--scroll');
      }
      if (this.get('transparent')) {
        classes.push('mdl-layout__header--transparent');
      }
      return classes.join(' ');
    }),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      var mdlnav = new window.MaterialLayout(this.get('element'));
      this.set('_mdlComponent', mdlnav);

      this.$('nav.mdl-navigation').on('click', function (jqEvt) {
        if (_this.get('closeDrawerOnItemClick') && jqEvt.target.className.indexOf('mdl-navigation__link') >= 0 && _this.$(jqEvt.target).closest('nav.mdl-navigation').closest('.mdl-layout__drawer').hasClass('is-visible')) {
          next(function () {
            var _mdlComponent = _this.get('_mdlComponent');
            _mdlComponent.drawer_.classList.remove(_mdlComponent.CssClasses_.IS_DRAWER_OPEN);
            _mdlComponent.obfuscator_.classList.remove(_mdlComponent.CssClasses_.IS_DRAWER_OPEN);
            // this.get('_mdlComponent').drawerToggleHandler_();
          });
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.$('nav.mdl-navigation').off('click');
    }
  });
});