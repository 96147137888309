define('phoenix/services/phoenix-socket', ['exports', 'phoenix'], function (exports, _phoenix) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(Ember.Evented, {
    socket: null,
    isHealthy: false,

    connect: function connect(url, options) {
      var _this = this,
          _arguments = arguments;

      var socket = new _phoenix.Socket(url, options);
      socket.onOpen(function () {
        if (_this.isDestroyed || _this.isDestroying) {
          return;
        }
        _this.set('isHealthy', true);
        _this.trigger.apply(_this, ['open'].concat(Array.prototype.slice.call(_arguments)));
      });
      socket.onClose(function () {
        if (_this.isDestroyed || _this.isDestroying) {
          return;
        }
        _this.set('isHealthy', false);
        _this.trigger.apply(_this, ['close'].concat(Array.prototype.slice.call(_arguments)));
      });
      socket.onError(function () {
        if (_this.isDestroyed || _this.isDestroying) {
          return;
        }
        _this.set('isHealthy', false);
        _this.trigger.apply(_this, ['error'].concat(Array.prototype.slice.call(_arguments)));
      });
      this.set('socket', socket);
      return socket.connect();
    },
    joinChannel: function joinChannel(name, params) {
      var socket = this.get('socket');
      (true && !(socket) && Ember.assert('must connect to a socket first', socket));


      var channel = socket.channel(name, params);
      channel.join();
      return channel;
    }
  });
});