define('ember-sauce-material-design/components/smd-drawer', ['exports', 'ember-sauce-material-design/templates/components/smd-drawer', 'ember-sauce-material-design/mixins/click-outside'], function (exports, _smdDrawer, _clickOutside) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Component.extend(_clickOutside.default, {
    layout: _smdDrawer.default,
    // Services
    appState: service('smd-app-state'),
    // Properties
    classNames: ['mdl-layout__drawer'],
    toggleButtonClass: 'mdl-layout__drawer-button',
    // Computed
    classNameBindings: ['appState.drawerVisibilityModifier'],
    // Methods
    clickOutside: function clickOutside() {
      this.set('appState.isDrawerVisible', false);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      // Hide drawer when navigation link is clicked.
      this.$('.mdl-navigation').on('click', function (e) {
        var targetIsNavigationLink = e.target.className.indexOf('mdl-navigation__link') !== -1;
        var targetIsWithinVisibleDrawer = _this.$(e.target).closest('.mdl-navigation').closest('.mdl-layout__drawer').hasClass('is-visible');
        if (targetIsNavigationLink && targetIsWithinVisibleDrawer) {
          Ember.run.next(function () {
            _this.set('appState.isDrawerVisible', false);
          });
        }
      });

      // Hide drawer when user clicks outside.
      Ember.run.next(this, this.addClickOutsideListener);
    },
    willDestroyElement: function willDestroyElement() {
      this.removeClickOutsideListener();
    }
  });
});