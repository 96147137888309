define('ember-sauce-material-design/components/smd-form-control', ['exports', 'ember-sauce-material-design/templates/components/smd-form-control'], function (exports, _smdFormControl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var computed = Ember.computed,
      Component = Ember.Component;
  exports.default = Component.extend({
    layout: _smdFormControl.default,
    tagName: 'div',
    classNames: ['smd-form__control', 'mdl-textfield', 'mdl-js-textfield'],
    classNameBindings: ['inputTypeModifier', 'floatingClass', 'invalidClass', 'validClass', 'inlineClass'],
    // Attributes
    name: null,
    disabled: false,
    label: null,
    model: null,
    type: 'text',
    icon: null,
    tip: null,
    isFloating: true,
    isInline: false,
    max: null,
    min: null,
    step: null,
    cols: null,
    rows: 3,
    format: 'DD/MM/YYYY',
    placeholder: null,
    useUTC: true,
    // Select Attributes
    nullOption: null,
    options: [],
    modelOptions: null,
    modelLabel: null,
    // Computed
    hasNullOption: computed.bool('nullOption'),
    /**
     * The options attribute maybe in one of 3 formats:
     * - flat
     * - objects
     * - models
     * This method will convert them all to a usable format for the hbs template
     */
    computedOptions: computed('options', 'modelOptions', 'modelLabel', 'value', function () {

      var options = this.get('options'),
          modelOptions = this.get('modelOptions'),
          modelLabel = this.get('modelLabel'),
          value = this.get('value'),
          name = this.get('name');

      var array = [];

      // Check options is an array
      if (!options && typeof options.forEach !== 'function') {
        Ember.Logger.error('Options attribute must be instance of array: ' + this.get('name'));
        Ember.Logger.log(options);
      }

      if (modelOptions) {
        modelOptions.forEach(function (obj) {
          var option = {};

          if (modelLabel) {
            option.label = obj.get(modelLabel);
          } else {
            Ember.Logger.warn('Define modelLabel for: ' + name);
            option.label = obj;
          }
          option.value = obj;

          if (value !== null && (typeof value === 'undefined' ? 'undefined' : _typeof(value)) === 'object' && value.get) {
            if (obj.id === value.get('id')) {
              if (option.set !== undefined && typeof option.set === 'function') {
                option.set('selected', true);
              } else {
                option.selected = true;
              }
            }
          }

          array.push(option);
        });
      } else {
        options.forEach(function (obj) {
          var option = {};

          if (obj !== null && (typeof obj === 'undefined' ? 'undefined' : _typeof(obj)) !== 'object') {
            // option is a flat value
            option.label = obj;
            option.value = obj;
            // check if selected
            if (obj === value) {
              if (option.set !== undefined && typeof option.set === 'function') {
                option.set('selected', true);
              } else {
                option.selected = true;
              }
            }
          } else {
            option = obj;
            // check if selected
            if (obj.value === value) {
              if (option.set !== undefined && typeof option.set === 'function') {
                option.set('selected', true);
              } else {
                option.selected = true;
              }
            }
          }

          array.push(option);
        });
      }

      return array;
    }),
    hasLabel: computed('label', 'type', function () {
      var label = this.get('label'),
          type = this.get('type');
      var noLabel = ['switch', 'checkbox'];
      if (noLabel.indexOf(type) !== -1) {
        return false;
      }
      return !!label;
    }),
    notValidating: computed.not('validation.isValidating'),
    didValidate: false,
    hasContent: computed.notEmpty('value'),
    isValid: computed.and('hasContent', 'validation.isValid', 'notValidating'),
    isInvalid: computed.oneWay('validation.isInvalid'),
    isTextarea: computed.equal('type', 'textarea'),
    isSwitch: computed.equal('type', 'switch'),
    isCheckbox: computed.equal('type', 'checkbox'),
    isRadio: computed.equal('type', 'radio'),
    isIcon: computed.equal('type', 'icon'),
    isSelect: computed.equal('type', 'select'),
    isDate: computed.equal('type', 'date'),
    showErrorClass: computed.and('notValidating', 'showErrorMessage', 'hasContent', 'validation'),
    showErrorMessage: computed('validation.isDirty', 'isInvalid', 'didValidate', function () {
      return (this.get('validation.isDirty') || this.get('didValidate')) && this.get('isInvalid');
    }),
    showWarningMessage: computed('validation.isDirty', 'validation.warnings.[]', 'isValid', 'didValidate', function () {
      return (this.get('validation.isDirty') || this.get('didValidate')) && this.get('isValid') && !Ember.isEmpty(this.get('validation.warnings'));
    }),
    showTip: computed('tip', function () {
      return this.get('tip');
    }),
    // Classes
    inputTypeModifier: computed('type', function () {
      return 'smd-form__control--' + this.get('type');
    }),
    floatingClass: computed('isFloating', function () {
      if (this.get('isFloating')) {
        return 'mdl-textfield--floating-label';
      }
    }),
    invalidClass: computed('isInvalid', function () {
      if ((this.get('validation.isDirty') || this.get('didValidate')) && this.get('isInvalid')) {
        return 'is-invalid';
      }
    }),
    validClass: computed('isValid', function () {
      if (this.get('isValid')) {
        return 'is-valid';
      }
    }),
    inlineClass: computed('isInline', 'type', function () {
      if (this.get('type') === 'radio') {
        if (this.get('isInline')) {
          return 'smd-form__control--inline-radio';
        }
      }
    }),
    inputClasses: computed('type', function () {
      var classNames = [];
      var type = this.get('type');

      if (type === 'range') {
        classNames.push('mdl-slider');
        classNames.push('mdl-js-slider');
      } else {
        classNames.push('mdl-textfield__input');
      }
      classNames.push('smd-form__input');
      classNames.push('smd-form__input--' + type);
      return classNames.join(' ');
    }),
    labelClasses: computed('type', function () {
      var classNames = [];
      classNames.push('mdl-textfield__label');
      classNames.push('smd-form__label');
      var type = this.get('type');
      var types = ['select', 'radio', 'icon', 'date', 'month', 'week', 'time', 'datetime', 'datetime-local', 'range'];
      if (types.indexOf(type) !== -1) {
        classNames.push('smd-form__label--fixed');
      }
      return classNames.join(' ');
    }),
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      var propName = this.get('name');
      if (this.get("model")) {
        Ember.defineProperty(this, 'validation', computed.oneWay('model.validations.attrs.' + propName));
        Ember.defineProperty(this, 'value', computed.alias('model.' + propName));
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      window.componentHandler.upgradeElement(this.element);
      this.applyFastClickFix();
    },
    applyFastClickFix: function applyFastClickFix() {
      if (this.get('isSwitch')) {
        this.$().find('.mdl-switch__ripple-container').addClass('needsclick');
      }
    },

    change: function change(e) {
      e.preventDefault();
      if (this.get('changeAction') !== undefined) {
        this.sendAction('changeAction');
      }
    },
    actions: {
      selectedOption: function selectedOption(option) {
        this.set('value', option);
      },
      checkOption: function checkOption(option) {
        this.set('value', option.value);
      },
      selectedDate: function selectedDate(date) {
        this.set('value', date);
      }
    }
  });
});