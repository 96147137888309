define('ember-select-box/mixins/select-box/select-box/keyboard-events', ['exports', 'ember-select-box/utils/invoke-action'], function (exports, _invokeAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var keys = {
    8: 'backspace',
    9: 'tab',
    13: 'enter',
    27: 'escape',
    37: 'left',
    38: 'up',
    39: 'right',
    40: 'down'
  };

  exports.default = Ember.Mixin.create({
    keyDown: function keyDown() {
      this._super.apply(this, arguments);
      this._keyPressAction.apply(this, arguments);
      this._keyPressMethod.apply(this, arguments);
    },
    _keyPressAction: function _keyPressAction(e) {
      var key = keys[e.which];
      if (!key) {
        return;
      }
      var actionName = 'on-press-' + key;
      (0, _invokeAction.default)(this, actionName, e, this.get('api'));
    },
    _keyPressMethod: function _keyPressMethod(e) {
      var key = Ember.String.capitalize(keys[e.which] || '');
      if (!key) {
        return;
      }
      var methodName = 'press' + key;
      var func = this[methodName];
      if (typeof func === 'function') {
        func.apply(this, arguments);
      }
    }
  });
});