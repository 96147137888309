define('ember-select-box/mixins/select-box/general/indexable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.set('components', this.getWithDefault('-components', Ember.A()));
    },


    index: Ember.computed('components', function () {
      return this.get('components').indexOf(this);
    })
  });
});