define('ember-material-lite/components/mdl-checkbox', ['exports', 'ember-material-lite/components/-base-toggle-component', 'ember-material-lite/templates/components/mdl-checkbox'], function (exports, _baseToggleComponent, _mdlCheckbox) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseToggleComponent.default.extend({
    primaryClassName: 'checkbox',
    layout: _mdlCheckbox.default,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var mdlcheckbox = new window.MaterialCheckbox(this.get('element'));
      this.set('_mdlComponent', mdlcheckbox);
    }
  });
});