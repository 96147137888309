define('ember-select-box/components/select-box/options', ['exports', 'ember-select-box/templates/components/select-box/options', 'ember-select-box/mixins/select-box/general/nameable', 'ember-select-box/mixins/select-box/general/styleable', 'ember-select-box/mixins/select-box/general/registerable'], function (exports, _options, _nameable, _styleable, _registerable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_nameable.default, _styleable.default, _registerable.default, {

    layout: _options.default,
    classNameSuffix: 'options',
    attributeBindings: ['aria-activedescendant']
  });
});