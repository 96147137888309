define('ember-select-box/utils/select-box/scroll-into-view', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = scrollIntoView;
  function scrollIntoView(element, parent) {
    if (!element || !parent) {
      return;
    }

    var $element = Ember.$(element);
    var $scroller = Ember.$(parent);
    var scroll = $scroller.scrollTop();
    var elementTop = $element.offset().top;
    var scrollerTop = $scroller.offset().top;
    var elementBottom = elementTop + $element.outerHeight();
    var scrollerBottom = scrollerTop + $scroller.innerHeight();
    var outOfBoundsTop = elementTop - scrollerTop < 0;
    var outOfBoundsBottom = elementBottom > scrollerBottom;

    if (outOfBoundsTop) {
      $scroller.scrollTop(scroll + (elementTop - scrollerTop));
    } else if (outOfBoundsBottom) {
      $scroller.scrollTop(scroll + (elementBottom - scrollerBottom));
    }
  }
});