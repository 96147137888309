define('ember-material-lite/components/mdl-menu-item', ['exports', 'ember-composability/mixins/child-component-support', 'ember-material-lite/components/-base-child-component', 'ember-material-lite/components/mdl-menu', 'ember-material-lite/templates/components/mdl-menu-item'], function (exports, _childComponentSupport, _baseChildComponent, _mdlMenu, _mdlMenuItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var A = Ember.A;
  exports.default = _baseChildComponent.default.extend(_childComponentSupport.default, {
    layout: _mdlMenuItem.default,
    tagName: 'li',
    _parentComponentTypes: A([_mdlMenu.default]),
    _childComponentClassStringDelimeter: '__',
    childComponentClassName: 'item',
    click: function click() {
      this.sendAction('action', this);
    }
  });
});