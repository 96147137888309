define('ember-sauce-material-design/components/smd-tab', ['exports', 'ember-sauce-material-design/templates/components/smd-tab'], function (exports, _smdTab) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var LinkComponent = Ember.LinkComponent,
      computed = Ember.computed,
      _Ember$computed = Ember.computed,
      alias = _Ember$computed.alias,
      bool = _Ember$computed.bool;
  exports.default = LinkComponent.extend({
    // Attributes
    layout: _smdTab.default,
    classNames: ['mdl-tabs__tab'],
    classNameBindings: ['themeClass'],
    // Computed
    hasParentView: bool('parentView'),
    theme: alias('parentView.theme'),
    themeClass: computed('hasParentView', 'theme', function () {
      if (this.get('hasParentView')) {
        return 'mdl-tabs__tab--' + this.get('theme');
      }
    }),
    // Methods
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      window.componentHandler.upgradeElement(this.element);
    }
  });
});