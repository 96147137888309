define('heating-timeline/components/period-bar', ['exports', 'heating-timeline/templates/components/period-bar', 'heating-timeline/mixins/transformable-component'], function (exports, _periodBar, _transformableComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Component = Ember.Component,
      service = Ember.inject.service;
  exports.default = Component.extend(_transformableComponent.default, {
    layout: _periodBar.default,
    // Services
    timeline: service('timeline'),
    // Attributes
    attributeBindings: ['x', 'y', 'width', 'height', 'transform'],
    classNames: ['schedule__bar'],
    classNameBindings: ['feelsClass'],
    height: 20,
    interval: 12.5,
    yScale: -5,
    minimized: false,
    model: null, // instance of period model
    offset: 0,
    tagName: 'rect',
    scale: 1,
    // Computed
    feelsClass: computed('model.temp', function () {
      if (this.get('model') == null) return;

      var temp = this.get('model.temp'),
          baseClass = 'schedule__bar';
      if (temp > 25) {
        return baseClass + '--hot';
      }
      if (temp > 19) {
        return baseClass + '--warm';
      }
      return baseClass + '--cool';
    }),
    width: computed('model.start', 'model.end', 'interval', function () {
      if (this.get('model') == null) return;

      var interval = this.get('interval');
      return (this.get('model.end') - this.get('model.start')) * interval;
    }),
    x: computed('model.start', 'interval', function () {
      if (this.get('model') == null) return;

      var interval = this.get('interval');
      return this.get('model.start') * interval;
    }),
    y: computed('minimized', 'model.temp', 'yScale', function () {
      if (this.get('model') == null) return;

      if (this.get('minimized')) {
        return -20;
      }
      return this.get('model.temp') * this.get('yScale');
    }),
    // Methods
    isActive: function isActive() {
      return this.get('timeline').isActivePeriod(this.get('model'));
    },
    // Events
    click: function click() {
      this.sendAction('action', this.get('model'));
    }
  });
});