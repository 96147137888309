define('ember-material-lite/components/mdl-card', ['exports', 'ember-material-lite/components/-base-toplevel-component', 'ember-material-lite/mixins/shadow-support', 'ember-material-lite/templates/components/mdl-card'], function (exports, _baseToplevelComponent, _shadowSupport, _mdlCard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseToplevelComponent.default.extend(_shadowSupport.default, {
    primaryClassName: 'card',
    _jsClass: false,
    layout: _mdlCard.default
  });
});