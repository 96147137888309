define('ember-select-box/mixins/select-box/general/registerable', ['exports', 'ember-select-box/utils/invoke-action'], function (exports, _invokeAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);
      (0, _invokeAction.default)(this, '-register', this);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      (0, _invokeAction.default)(this, '-deregister', this);
    }
  });
});