define('ember-select-box/components/select-box', ['exports', 'ember-select-box/templates/components/select-box', 'ember-select-box/mixins/select-box/base', 'ember-select-box/mixins/select-box/select-box/api', 'ember-select-box/mixins/select-box/select-box/toggleable', 'ember-select-box/mixins/select-box/select-box/disableable', 'ember-select-box/mixins/select-box/registration/has-input', 'ember-select-box/mixins/select-box/registration/has-options-container', 'ember-select-box/mixins/select-box/registration/has-selected-options', 'ember-select-box/mixins/select-box/registration/has-selected-options-container', 'ember-select-box/mixins/select-box/select-box/inputtable', 'ember-select-box/mixins/select-box/select-box/searchable', 'ember-select-box/mixins/select-box/select-box/activatable-options', 'ember-select-box/mixins/select-box/select-box/keyboard-events', 'ember-select-box/mixins/select-box/select-box/click-outside-event', 'ember-select-box/mixins/select-box/select-box/activatable-selected-options', 'ember-select-box/mixins/select-box/select-box/select-active-option', 'ember-select-box/mixins/select-box/select-box/select-active-option-on-enter'], function (exports, _selectBox, _base, _api, _toggleable, _disableable, _hasInput, _hasOptionsContainer, _hasSelectedOptions, _hasSelectedOptionsContainer, _inputtable, _searchable, _activatableOptions, _keyboardEvents, _clickOutsideEvent, _activatableSelectedOptions, _selectActiveOption, _selectActiveOptionOnEnter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_base.default, _toggleable.default, _disableable.default, _api.default, _clickOutsideEvent.default, _keyboardEvents.default, _hasInput.default, _hasOptionsContainer.default, _hasSelectedOptions.default, _hasSelectedOptionsContainer.default, _inputtable.default, _searchable.default, _activatableOptions.default, _activatableSelectedOptions.default, _selectActiveOption.default, _selectActiveOptionOnEnter.default, {

    layout: _selectBox.default,
    ariaRole: 'listbox',
    attributeBindings: ['aria-label', 'isOpen:aria-expanded', 'isDisabled:aria-disabled'],
    classNameBindings: ['isOpen', 'isFocused', 'isDisabled', 'isSearching', 'isSlowSearch', 'isMultiple']
  });
});