define('ember-material-lite/mixins/shadow-support', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    classNameBindings: ['_shadowClassString'],
    shadowDepth: 2,
    _shadowClassString: computed('shadowDepth', function () {
      return 'mdl-shadow--' + this.get('shadowDepth') + 'dp';
    })
  });
});