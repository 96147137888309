define('ember-material-lite/components/mdl-footer-section', ['exports', 'ember-material-lite/components/mdl-mini-footer', 'ember-material-lite/components/mdl-mega-footer', 'ember-composability/mixins/child-component-support', 'ember-material-lite/components/-base-child-component', 'ember-material-lite/templates/components/mdl-footer-section'], function (exports, _mdlMiniFooter, _mdlMegaFooter, _childComponentSupport, _baseChildComponent, _mdlFooterSection) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      A = Ember.A;
  exports.default = _baseChildComponent.default.extend(_childComponentSupport.default, {
    _parentComponentTypes: A([_mdlMegaFooter.default, _mdlMiniFooter.default]),
    layout: _mdlFooterSection.default,
    align: 'middle',
    childComponentClassName: computed('align', function () {
      return this.get('align') + '-section';
    })
  });
});