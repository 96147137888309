define('ember-material-lite/components/-base-toggle-component', ['exports', 'ember-material-lite/components/-base-toplevel-component', 'ember-material-lite/mixins/ripple-support', 'ember-material-lite/mixins/click-action-support', 'ember-material-lite/templates/components/mdl-checkbox'], function (exports, _baseToplevelComponent, _rippleSupport, _clickActionSupport, _mdlCheckbox) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var deprecate = Ember.deprecate,
      computed = Ember.computed;
  exports.default = _baseToplevelComponent.default.extend(_rippleSupport.default, _clickActionSupport.default, {
    tagName: 'label',
    layout: _mdlCheckbox.default,
    value: false,
    label: computed('text', {
      get: function get() {
        deprecate('The \'label\' property has been deprecated. Please use \'text\' instead', false);
        return this.get('text');
      },
      set: function set(key, val) {
        deprecate('The \'label\' property has been deprecated. Please use \'text\' instead', false);
        this.set('text');
        return val;
      }
    }),
    text: '',
    attributeBindings: ['_inputId:for'],
    _inputId: computed('elementId', function () {
      return this.get('elementId') + '-input';
    })
  });
});