define('ember-material-lite/components/mdl-radio', ['exports', 'ember-material-lite/components/-base-toggle-component', 'ember-material-lite/templates/components/mdl-radio'], function (exports, _baseToggleComponent, _mdlRadio) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var oneWay = Ember.computed.oneWay;
  exports.default = _baseToggleComponent.default.extend({
    primaryClassName: 'radio',
    layout: _mdlRadio.default,
    checked: false,
    name: oneWay('_defaultName'),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var mdlradio = new window.MaterialRadio(this.get('element'));
      this.element.MaterialRadio = mdlradio;
      this.set('_mdlComponent', mdlradio);
    },


    _defaultName: 'default'
  });
});