define('ember-sauce-material-design/components/smd-tabs', ['exports', 'ember-sauce-material-design/templates/components/smd-tabs'], function (exports, _smdTabs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    // Attributes
    layout: _smdTabs.default,
    align: 'center', // left, right & center
    theme: 'default', // default, primary, accent
    tagName: 'nav',
    classNames: ['mdl-tabs', 'mdl-js-tabs', 'mdl-js-ripple-effect'],
    classNameBindings: ['themeClass'],
    // Computed
    themeClass: computed('theme', function () {
      return 'mdl-tabs--' + this.get('theme');
    }),
    tabBarClasses: computed('align', 'theme', function () {
      return 'mdl-tabs__tab-bar--' + this.get('align');
    }),
    // Methods
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      window.componentHandler.upgradeElement(this.element);
    }
  });
});