define('ember-material-lite/components/mdl-button', ['exports', 'ember-material-lite/components/-base-toplevel-component', 'ember-material-lite/mixins/ripple-support', 'ember-material-lite/mixins/click-action-support', 'ember-material-lite/templates/components/mdl-button'], function (exports, _baseToplevelComponent, _rippleSupport, _clickActionSupport, _mdlButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _baseToplevelComponent.default.extend(_rippleSupport.default, _clickActionSupport.default, {
    primaryClassName: 'button',
    tagName: 'button',
    icon: null,
    isColored: true,
    isRaised: false,
    isFloating: false,
    isMiniFab: false,
    isAccent: false,
    _mdlComponent: null,
    'for': null,
    _isIconMode: computed('icon', 'isFloating', function () {
      return !this.get('isFloating') && this.get('icon');
    }),
    attributeBindings: ['disabled', 'for', 'type:buttonType'],
    buttonType: 'button',
    classNameBindings: ['isMiniFab:mdl-button--mini-fab', 'isAccent:mdl-button--accent', 'isRaised:mdl-button--raised', '_isIconMode:mdl-button--icon', 'isColored:mdl-button--colored', 'isFloating:mdl-button--fab'],
    layout: _mdlButton.default,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var mdlbtn = new window.MaterialButton(this.get('element'));
      this.set('_mdlComponent', mdlbtn);
    }
  });
});