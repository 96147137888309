define('ember-material-lite/components/mdl-mini-footer', ['exports', 'ember-composability/mixins/parent-component-support', 'ember-material-lite/components/-base-toplevel-component', 'ember-material-lite/templates/components/mdl-mini-footer'], function (exports, _parentComponentSupport, _baseToplevelComponent, _mdlMiniFooter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseToplevelComponent.default.extend(_parentComponentSupport.default, {
    tagName: 'footer',
    primaryClassName: 'mini-footer',
    layout: _mdlMiniFooter.default
  });
});