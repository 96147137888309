define('ember-sauce-material-design/services/smd-dialog-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service,
      $ = Ember.$;
  exports.default = Service.extend({
    alerts: Ember.A([]),
    registerAlert: function registerAlert(id) {
      if (this.getAlert(id) === null) {
        Ember.Logger.info('Alert already registered with id=' + id);
      } else {
        // register dialog with pollyfill
        var dialog = document.querySelector('#' + id);
        dialogPolyfill.registerDialog(dialog);

        this.get('alerts').push(Ember.Object.create({
          id: id,
          visible: false
        }));
      }
    },
    showAlert: function showAlert(id) {
      var alert = this.getAlert(id);
      alert.set('visible', true);
      $('#' + alert.get('id'))[0].showModal();
    },
    hideAlert: function hideAlert(id) {
      var alert = this.getAlert(id);
      alert.set('visible', false);
      $('#' + alert.get('id'))[0].close();
    },
    getAlert: function getAlert(id) {
      var results = this.get('alerts').filterBy('id', id);
      if (results.length > 0) {
        return results[0];
      }
    }
  });
});