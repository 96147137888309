define('ember-material-lite/mixins/ripple-support', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin,
      computed = Ember.computed,
      observer = Ember.observer;
  exports.default = Mixin.create({
    hasRipples: true,
    _rippleClass: computed('_primaryClassNameString', function () {
      return this.get('_primaryClassNameString') + '__ripple-container';
    }),
    classNameBindings: ['hasRipples:mdl-js-ripple-effect'],

    _mdlComponentObserver: observer('_mdlComponent', function () {
      if (this.get('hasRipples')) {
        var mdlripples = new window.MaterialRipple(this.$('.' + this.get('_rippleClass'))[0]);
        this.set('_mdlRippleComponent', mdlripples);
      }
    })
  });
});