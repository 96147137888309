define('ember-material-lite/components/mdl-icon', ['exports', 'ember-material-lite/mixins/badge-support', 'ember-material-lite/templates/components/mdl-icon'], function (exports, _badgeSupport, _mdlIcon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend(_badgeSupport.default, {
    classNames: ['icon', 'material-icons'],
    layout: _mdlIcon.default
  });
});