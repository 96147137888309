define('ember-material-lite/components/mdl-card-actions', ['exports', 'ember-material-lite/templates/components/mdl-card-actions'], function (exports, _mdlCardActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['mdl-card__actions mdl-card--border'],
    layout: _mdlCardActions.default
  });
});