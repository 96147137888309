define('ember-select-box/mixins/select-box/registration/has-options-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    actions: {
      _registerOptionsContainer: function _registerOptionsContainer(container) {
        (true && !(!this.get('optionsContainer')) && Ember.assert('A select box can only have 1 options container', !this.get('optionsContainer')));

        this.set('optionsContainer', container);
      },
      _deregisterOptionsContainer: function _deregisterOptionsContainer() {
        this.set('optionsContainer', null);
      }
    }
  });
});