define('ember-select-box/mixins/select-box/select-box/activatable-options', ['exports', 'ember-select-box/utils/select-box/scroll-into-view'], function (exports, _scrollIntoView) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);
      this._deactivateOptions();
    },
    _activateOptionAtIndex: function _activateOptionAtIndex(index, scroll) {
      var under = index < 0;
      var over = index > this.get('options.length') - 1;
      if (!(under || over)) {
        this.set('activeOptionIndex', index);
        this._activatedOption();
      }
      if (scroll) {
        this._scrollActiveOptionIntoView();
      }
    },
    _activatedOption: function _activatedOption() {
      var activeOption = this.get('activeOption');
      if (activeOption) {
        activeOption.send('_activate');
      }
    },
    _deactivateOptions: function _deactivateOptions() {
      this.set('activeOptionIndex', -1);
    },
    _scrollActiveOptionIntoView: function _scrollActiveOptionIntoView() {
      (0, _scrollIntoView.default)(this.get('activeOption.element'), this.get('optionsContainer.element'));
    },


    activeOption: Ember.computed('activeOptionIndex', 'options', function () {
      return this.get('options').objectAt(this.get('activeOptionIndex'));
    }),

    actions: {
      activateOptionAtIndex: function activateOptionAtIndex(index, scroll) {
        this._activateOptionAtIndex(index, scroll);
      },
      activateNextOption: function activateNextOption(scroll) {
        var next = this.get('activeOptionIndex') + 1;
        this._activateOptionAtIndex(next, scroll);
      },
      activatePreviousOption: function activatePreviousOption(scroll) {
        var prev = this.get('activeOptionIndex') - 1;
        this._activateOptionAtIndex(prev, scroll);
      },
      deactivateOptions: function deactivateOptions() {
        this._deactivateOptions();
      }
    }
  });
});