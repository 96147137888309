define('ember-sauce-material-design/components/smd-snackbar', ['exports', 'ember-sauce-material-design/templates/components/smd-snackbar'], function (exports, _smdSnackbar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _smdSnackbar.default,
    id: 'app-snackbar',
    classNames: ['mdl-js-snackbar', 'mdl-snackbar'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      window.componentHandler.upgradeElement(this.element);
    }
  });
});