define('ember-material-lite/mixins/registers-buttons', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var A = Ember.A,
      Mixin = Ember.Mixin,
      empty = Ember.computed.empty;
  exports.default = Mixin.create({
    _registersButtons: true,
    _buttons: null,
    _noButtons: empty('_buttons'),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('_buttons', A([]));
    },
    registerButton: function registerButton(button) {
      this.get('_buttons').addObject(button);
    }
  });
});