define('ember-select-box/mixins/select-box/select-box/activatable-selected-options', ['exports', 'ember-select-box/utils/select-box/scroll-into-view'], function (exports, _scrollIntoView) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);
      this._deactivateSelectedOptions();
    },
    _activateSelectedOptionAtIndex: function _activateSelectedOptionAtIndex(index, scroll) {
      var under = index < 0;
      var over = index > this.get('selectedOptions.length') - 1;
      if (!(under || over)) {
        this.set('activeSelectedOptionIndex', index);
      }
      if (scroll) {
        this._scrollActiveSelectedOptionIntoView();
      }
    },
    _deactivateSelectedOptions: function _deactivateSelectedOptions() {
      this.set('activeSelectedOptionIndex', -1);
    },
    _scrollActiveSelectedOptionIntoView: function _scrollActiveSelectedOptionIntoView() {
      (0, _scrollIntoView.default)(this.get('activeSelectedOption.element'), this.get('selectedOptionsContainer.element'));
    },


    activeSelectedOption: Ember.computed('activeSelectedOptionIndex', 'selectedOptions', function () {
      var index = this.get('activeSelectedOptionIndex');
      return this.get('selectedOptions').objectAt(index);
    }),

    actions: {
      activateSelectedOptionAtIndex: function activateSelectedOptionAtIndex(index, scroll) {
        this._activateSelectedOptionAtIndex(index, scroll);
      },
      activateNextSelectedOption: function activateNextSelectedOption(scroll) {
        var next = this.get('activeSelectedOptionIndex') + 1;
        this._activateSelectedOptionAtIndex(next, scroll);
      },
      activatePreviousSelectedOption: function activatePreviousSelectedOption(scroll) {
        var prev = this.get('activeSelectedOptionIndex') - 1;
        this._activateSelectedOptionAtIndex(prev, scroll);
      },
      deactivateSelectedOptions: function deactivateSelectedOptions() {
        this._deactivateSelectedOptions();
      }
    }
  });
});