define('ember-sauce-material-design/components/smd-empty', ['exports', 'ember-sauce-material-design/templates/components/smd-empty'], function (exports, _smdEmpty) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _smdEmpty.default,
    tagName: 'div',
    classNames: ['smd-empty'],
    text: null,
    icon: 'sentiment_dissatisfied'
  });
});