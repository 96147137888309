define('ember-bem-sauce/utils/get-bem-modifiers', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = getBemModifiers;
  /**
   * Return an array of active modifiers.
   * Modifiers should be an array of property names to add if
   * they are true on the context. Use 'property:modifier' syntax
   * if modifier should be different from property name.
   *
   * @prop modifiers  Array   Array of modifiers ()
   * @prop context    Object  The object that has the modifiers properties
   */
  function getBemModifiers(modifiers, context) {

    var classNames = [];

    modifiers.forEach(function (modifier) {
      var property = modifier;

      if (modifier.indexOf(':') !== -1) {
        var arr = modifier.split(':');
        property = arr[0];
        modifier = arr[1];
      }

      if (context.get(property)) {
        classNames.push(modifier);
      }
    });
    return classNames.sort();
  }
});