define('ember-sauce-material-design/components/smd-page-body', ['exports', 'ember-sauce-material-design/templates/components/smd-page-body'], function (exports, _smdPageBody) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _smdPageBody.default,
    tagName: 'section',
    classNames: ['smd-page__body']
  });
});