define('ember-sauce-material-design/services/smd-header-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var copy = Ember.copy,
      merge = Ember.merge,
      Service = Ember.Service;
  exports.default = Service.extend({
    // Attributes
    title: null,
    backIcon: null,
    crumb: null,
    toolbarIconOne: null,
    toolbarIconTwo: null,
    toolbarIconThree: null,
    toolbarOptionsOne: null,
    toolbarOptionsTwo: null,
    toolbarOptionsThree: null,
    toolbarSelectedOptionOne: null,
    toolbarSelectedOptionTwo: null,
    toolbarSelectedOptionThree: null,
    fabIcon: null,
    isMiniFab: null,
    isRightFab: null,
    coverImageClass: null,
    coverImageSrc: null,
    isMinimized: null,
    scrolling: null,
    scrollContext: null,
    // Actions
    action: null,
    backAction: null,
    fabAction: null,
    toolbarActionOne: null,
    toolbarActionTwo: null,
    toolbarActionThree: null,
    searchAction: false,
    searchTerm: null,
    // Methods
    clear: function clear() {
      var _this = this;

      var defaults = copy(this.get('_defaults'));
      Object.keys(defaults).forEach(function (key) {
        _this.set(key, defaults[key]);
      });
    },
    setup: function setup(hash) {
      var _this2 = this;

      var defaults = copy(this.get('_defaults'));
      merge(defaults, hash);
      Object.keys(defaults).forEach(function (key) {
        _this2.set(key, defaults[key]);
      });
    },
    // Private Attributes
    _defaults: {
      // Attributes
      title: null,
      crumb: null,
      backIcon: null,
      toolbarIconOne: null,
      toolbarIconTwo: null,
      toolbarIconThree: null,
      toolbarOptionsOne: null,
      toolbarOptionsTwo: null,
      toolbarOptionsThree: null,
      toolbarSelectedOptionOne: null,
      toolbarSelectedOptionTwo: null,
      toolbarSelectedOptionThree: null,
      fabIcon: null,
      isMiniFab: false,
      isRightFab: false,
      coverImageClass: null,
      coverImageSrc: null,
      isMinimized: null,
      scrolling: null,
      scrollContext: null,
      // Actions
      action: null,
      backAction: null,
      fabAction: null,
      toolbarActionOne: null,
      toolbarActionTwo: null,
      toolbarActionThree: null,
      searchAction: false,
      searchTerm: null
    }
  });
});