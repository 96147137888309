define('ember-select-box/components/select-box/selected-option', ['exports', 'ember-select-box/templates/components/select-box/option', 'ember-select-box/mixins/select-box/option/base', 'ember-select-box/mixins/select-box/general/styleable', 'ember-select-box/mixins/select-box/general/indexable', 'ember-select-box/mixins/select-box/option/activatable'], function (exports, _option, _base, _styleable, _indexable, _activatable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_base.default, _styleable.default, _indexable.default, _activatable.default, {

    layout: _option.default,
    classNameSuffix: 'selected-option',
    attributeBindings: ['title', 'isActive:aria-current'],
    classNameBindings: ['isActive']
  });
});