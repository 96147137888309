define('ember-select-box/mixins/select-box/option/base', ['exports', 'ember-select-box/mixins/select-box/general/nameable', 'ember-select-box/mixins/select-box/general/registerable', 'ember-select-box/utils/try-set'], function (exports, _nameable, _registerable, _trySet) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_nameable.default, _registerable.default, {

    promiseID: 0,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this._update();
    },
    _update: function _update() {
      var value = this.get('value');
      var id = this.get('promiseID') + 1;

      (0, _trySet.default)(this, 'promiseID', id);

      var success = Ember.run.bind(this, '_resolvedValue', id, false);
      var failure = Ember.run.bind(this, '_resolvedValue', id, true);

      Ember.RSVP.resolve(value).then(success, failure);
    },
    _resolvedValue: function _resolvedValue(id, failed, value) {
      var superseded = id < this.get('promiseID');

      if (superseded || this.get('isDestroyed')) {
        return;
      }

      var label = this.get('label');

      if (failed) {
        label = '' + value;
      }

      if (Ember.isBlank(label)) {
        label = value;
      }

      (0, _trySet.default)(this, 'label', label);
      (0, _trySet.default)(this, 'value', value);
    }
  });
});