define('ember-select-box/components/select-box/native/option', ['exports', 'ember-select-box/mixins/select-box/option/base', 'ember-select-box/mixins/select-box/option/selectable', 'ember-select-box/templates/components/select-box/native/option'], function (exports, _base, _selectable, _option) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_base.default, _selectable.default, {

    layout: _option.default,
    tagName: 'option',
    attributeBindings: ['isSelected:selected', 'value', 'disabled', 'title'],
    classNameSuffix: 'option'
  });
});