define('ember-sauce-material-design/components/smd-page-header', ['exports', 'ember-sauce-material-design/templates/components/smd-page-header'], function (exports, _smdPageHeader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      bool = Ember.computed.bool;
  exports.default = Component.extend({
    // Attributes
    layout: _smdPageHeader.default,
    tagName: 'header',
    classNames: ['smd-page__header'],
    title: null,
    // Computed
    hasTitle: bool('title')
  });
});