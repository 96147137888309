define('ember-sauce-material-design/components/smd-dropdown-item', ['exports', 'ember-sauce-material-design/templates/components/smd-dropdown-item', 'ember-sauce-material-design/mixins/click-support'], function (exports, _smdDropdownItem, _clickSupport) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      bool = Ember.computed.bool;
  exports.default = Component.extend(_clickSupport.default, {
    layout: _smdDropdownItem.default,
    tagName: 'div',
    classNames: ['smd-menu__item', 'mdl-menu__item'],
    classNameBindings: ['divider:mdl-menu__item--full-bleed-divider', 'hasIcon:mdl-menu__item--icon'],
    attributeBindings: ['disabled:disabled'],
    disabled: false,
    divider: false,
    text: null,
    icon: null,
    hasIcon: bool('icon')
  });
});