define('ember-sauce-material-design/mixins/click-outside', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;

  var bound = function bound(fnName) {
    return computed(fnName, function () {
      return this.get(fnName).bind(this);
    });
  };

  exports.default = Ember.Mixin.create({
    clickOutsideSelector: null,
    clickOutside: function clickOutside() {},

    clickHandler: bound('outsideClickHandler'),

    outsideClickHandler: function outsideClickHandler(e) {
      var element = this.get('element');
      var clickOutsideSelector = this.get('clickOutsideSelector');
      if (clickOutsideSelector) {
        element = element.querySelector(clickOutsideSelector);
      }
      var $target = Ember.$(e.target);
      var isInside = $target.closest(element).length === 1;
      var toggleButtonClass = this.get('toggleButtonClass');
      var isToggleButton = $target[0].className === toggleButtonClass || $target[0].parentNode.className === toggleButtonClass;

      if (!isInside && !isToggleButton) {
        this.clickOutside(e);
      }
    },
    addClickOutsideListener: function addClickOutsideListener() {
      var clickHandler = this.get('clickHandler');
      Ember.$(window).on('click', clickHandler);
    },
    removeClickOutsideListener: function removeClickOutsideListener() {
      var clickHandler = this.get('clickHandler');
      Ember.$(window).off('click', clickHandler);
    }
  });
});