define('ember-sauce-material-design/services/smd-header-stack-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service,
      service = Ember.inject.service;
  exports.default = Service.extend({
    // Services
    header: service('smd-header-service'),
    // Attributes
    _stack: [],
    _defaults: {
      // Attributes
      title: null,
      backIcon: null,
      crumb: null,
      toolbarIconOne: null,
      toolbarIconTwo: null,
      toolbarIconThree: null,
      toolbarOptionsOne: null,
      toolbarOptionsTwo: null,
      toolbarOptionsThree: null,
      toolbarSelectedOptionOne: null,
      toolbarSelectedOptionTwo: null,
      toolbarSelectedOptionThree: null,
      fabIcon: null,
      isMiniFab: null,
      isRightFab: null,
      coverImageClass: null,
      coverImageSrc: null,
      isMinimized: null,
      scrolling: null,
      scrollContext: null,
      // Actions
      action: null,
      backAction: null,
      fabAction: null,
      toolbarActionOne: null,
      toolbarActionTwo: null,
      toolbarActionThree: null,
      searchAction: false,
      searchTerm: null
    },
    // Public Methods
    clear: function clear() {
      this.set('_stack', []);
    },
    push: function push(props) {
      this.get('_stack').push(props);
      this.refresh();
    },
    pop: function pop() {
      this.get('_stack').pop();
      this.refresh();
    },
    refresh: function refresh() {
      var merged = this.getHash();
      this.get('header').setup(merged);
    },
    getHash: function getHash() {
      var hash = this._mergeProps();
      return hash;
    },

    // Private Methods
    _mergeDefaults: function _mergeDefaults(props) {
      var defaults = Ember.Object.create(this.get('_defaults'));
      return Ember.merge(defaults, props);
    },
    _mergeProps: function _mergeProps() {
      var stack = this.get('_stack'),
          merged = stack.reduce(function (acc, props) {
        return Ember.merge(acc, props);
      }, {});
      return this._mergeDefaults(merged);
    }
  });
});