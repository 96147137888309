define('ember-select-box/mixins/select-box/base', ['exports', 'ember-select-box/mixins/select-box/general/nameable', 'ember-select-box/mixins/select-box/registration/has-options', 'ember-select-box/mixins/select-box/select-box/focusable', 'ember-select-box/utils/try-set', 'ember-select-box/utils/invoke-action'], function (exports, _nameable, _hasOptions, _focusable, _trySet, _invokeAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_nameable.default, _hasOptions.default, _focusable.default, {

    api: null,
    promiseID: 0,

    init: function init() {
      this._super.apply(this, arguments);
      this._init();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.set('isMultiple', this.get('multiple'));

      if (this.get('value') !== this.get('selectedValue') || !this.get('doneInitialUpdate')) {
        this._update(this.get('value'));
        this.set('doneInitialUpdate', true);
      }
    },
    _select: function _select(value) {
      var _this = this;

      this._update(value).then(function () {
        _this._selected();
      });
    },
    _update: function _update(value) {
      value = this._normaliseValue(value);
      value = this._resolveValue(value);

      var id = this.get('promiseID') + 1;

      (0, _trySet.default)(this, 'promiseID', id);

      var success = Ember.run.bind(this, '_resolvedValue', id, false);
      var failure = Ember.run.bind(this, '_resolvedValue', id, true);

      return value.then(success, failure);
    },
    _init: function _init() {
      (0, _invokeAction.default)(this, 'on-init', this.get('api'));
    },
    _updated: function _updated() {
      (0, _invokeAction.default)(this, 'on-update', this.get('selectedValue'), this.get('api'));
    },
    _selected: function _selected() {
      (0, _invokeAction.default)(this, 'on-select', this.get('selectedValue'), this.get('api'));
    },
    _normaliseValue: function _normaliseValue(value) {
      if (this.get('isMultiple')) {
        value = Ember.makeArray(value);
      }
      return value;
    },
    _resolveValue: function _resolveValue(value) {
      if (this.get('isMultiple')) {
        return Ember.RSVP.all(value);
      }
      return Ember.RSVP.resolve(value);
    },
    _resolvedValue: function _resolvedValue(id, failed, value) {
      var superseded = id < this.get('promiseID');

      if (superseded || this.get('isDestroyed')) {
        return;
      }

      this.set('selectedValue', value);

      this.rerender();

      Ember.run.scheduleOnce('afterRender', this, '_updated');
    },


    actions: {
      update: function update(value) {
        this._update(value);
      },
      select: function select(value) {
        this._select(value);
      }
    }
  });
});