define('ember-select-box/mixins/select-box/general/document-click', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    $document: Ember.computed(function () {
      return Ember.$(document);
    }),

    clickDocument: function clickDocument() {},
    init: function init() {
      this._super.apply(this, arguments);
      var guid = Ember.guidFor(this);
      this.set('clickEventName', 'click.' + guid);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      this.get('$document').on(this.get('clickEventName'), function () {
        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        if (_this.get('isDestroyed')) {
          return;
        }
        Ember.run.apply(undefined, [_this, 'clickDocument'].concat(args));
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('$document').off(this.get('clickEventName'));
    }
  });
});