define('ember-material-lite/components/mdl-mega-footer', ['exports', 'ember-composability/mixins/parent-component-support', 'ember-material-lite/components/-base-toplevel-component', 'ember-material-lite/templates/components/mdl-mega-footer'], function (exports, _parentComponentSupport, _baseToplevelComponent, _mdlMegaFooter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseToplevelComponent.default.extend(_parentComponentSupport.default, {
    primaryClassName: 'mega-footer',
    _jsClass: false,
    tagName: 'footer',
    layout: _mdlMegaFooter.default
  });
});