define('ember-sauce-material-design/components/smd-alert', ['exports', 'ember-sauce-material-design/templates/components/smd-alert'], function (exports, _smdAlert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      _Ember$computed = Ember.computed,
      bool = _Ember$computed.bool,
      or = _Ember$computed.or,
      service = Ember.inject.service;
  exports.default = Component.extend({
    // Service
    dialogService: service('smd-dialog-service'),
    // Attributes
    layout: _smdAlert.default,
    tagName: 'dialog',
    classNames: ['smd-alert'],
    title: null,
    affirmativeText: null,
    dismissiveText: null,
    affirmativeAction: null,
    dismissiveAction: false,
    // Content data to yield
    data: null,
    // Computed
    hasTitle: bool('title'),
    hasFooter: or('affirmativeText', 'dismissiveText'),
    hasAffirmative: bool('affirmativeText'),
    hasDismissive: bool('dismissiveText'),
    // Actions
    actions: {
      affirmativeAction: function affirmativeAction() {
        if (this.get('affirmativeAction')) {
          this.sendAction('affirmativeAction', this);
        } else {
          this.sendAction('action', 'confirm', this);
        }
      },
      dismissiveAction: function dismissiveAction() {
        if (this.get('dismissiveAction') === false) {
          this.get('dialogService').hideAlert(this.elementId);
        } else if (this.get('dismissiveAction')) {
          this.sendAction('dismissiveAction', this);
        } else {
          this.sendAction('action', 'dismiss', this);
        }
      }
    },
    keyDown: function keyDown(e) {
      // esc
      if (e.keyCode === 27) {
        this.send('dismissiveAction');
      }
    },
    didInsertElement: function didInsertElement() {
      this.get('dialogService').registerAlert(this.elementId);
    }
  });
});