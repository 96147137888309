define('heating-timeline/services/timeline', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    scale: 1,
    offset: 0,
    activePeriod: null,
    /**
     * Reset the timeline to 24hr view
     */
    reset: function reset() {
      this.set('scale', 1);
      this.set('offset', 0);
    },
    /**
     * Zoom in to a give time period
     */
    goToPeriod: function goToPeriod(start, end) {
      // find length of period
      var diff = end - start;
      // double it
      diff = diff * 2;
      // exit if we have an invalid period
      if (diff <= 0) {
        return;
      }
      // scale the timeline so 2 periods will fit
      var scale = 24 / diff,

      // set offset so there is a 25% offset before the selected period
      offset = start - diff / 4;
      // set scale and offset attributes
      this.set('scale', scale);
      this.set('offset', offset);
    },
    /**
     * Return true if the give period is active
     */
    isActivePeriod: function isActivePeriod(period) {
      return period === this.get('activePeriod');
    }
  });
});