define('ember-select-box/components/select-box/input', ['exports', 'ember-select-box/mixins/select-box/general/nameable', 'ember-select-box/mixins/select-box/general/registerable', 'ember-select-box/utils/invoke-action'], function (exports, _nameable, _registerable, _invokeAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_nameable.default, _registerable.default, {

    tagName: 'input',
    type: '',

    attributeBindings: ['accept', 'autocapitalize', 'autocomplete', 'autocorrect', 'autofocus', 'autosave', 'dir', 'disabled', 'form', 'formaction', 'formenctype', 'formmethod', 'formnovalidate', 'formtarget', 'height', 'inputmode', 'lang', 'list', 'max', 'maxlength', 'min', 'minlength', 'multiple', 'name', 'pattern', 'placeholder', 'readonly', 'required', 'selectionDirection', 'size', 'spellcheck', 'step', 'tabindex', 'title', 'type', 'value', 'width'],

    classNameSuffix: 'input',

    input: function input() {
      this._super.apply(this, arguments);
      var value = this.get('element.value');
      if (!value) {
        (0, _invokeAction.default)(this, 'on-clear', this.get('-api'));
      }
      (0, _invokeAction.default)(this, '-on-input', value);
      (0, _invokeAction.default)(this, 'on-input', value, this.get('-api'));
    },
    keyDown: function keyDown(e) {
      this._super.apply(this, arguments);
      if (e.which === 8 && !this.get('element.value')) {
        (0, _invokeAction.default)(this, 'on-delete', this.get('-api'));
      }
    }
  });
});