define('ember-select-box/components/select-box/option', ['exports', 'ember-select-box/templates/components/select-box/option', 'ember-select-box/mixins/select-box/option/base', 'ember-select-box/mixins/select-box/general/styleable', 'ember-select-box/mixins/select-box/option/selectable', 'ember-select-box/mixins/select-box/option/activatable', 'ember-select-box/mixins/select-box/general/disableable', 'ember-select-box/mixins/select-box/general/indexable', 'ember-select-box/utils/invoke-action'], function (exports, _option, _base, _styleable, _selectable, _activatable, _disableable, _indexable, _invokeAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_base.default, _selectable.default, _styleable.default, _indexable.default, _activatable.default, _disableable.default, {

    layout: _option.default,
    classNameSuffix: 'option',
    ariaRole: 'option',
    attributeBindings: ['isActive:aria-current', 'isDisabled:aria-disabled', 'isSelected:aria-selected', 'title'],
    classNameBindings: ['isActive', 'isDisabled', 'isSelected'],

    mouseEnter: function mouseEnter() {
      this.send('activate');
    },
    click: function click() {
      this.send('select');
    },


    actions: {
      select: function select() {
        this._super.apply(this, arguments);
        (0, _invokeAction.default)(this, 'on-select', this.get('value'), this.get('-api'));
      }
    }
  });
});