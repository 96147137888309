define('ember-sauce-material-design/utils/smd-transitions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.tabTransitions = tabTransitions;
  function tabTransitions(scope, routeNames) {
    var inTransition = 'toRight',
        outTransition = 'toLeft';

    routeNames.forEach(function (routeName, index, routeNames) {
      var currentRouteName = routeName;
      for (var i = index + 1; i < routeNames.length; i++) {
        var targetRoute = routeNames[i];
        scope.transition(scope.fromRoute(currentRouteName), scope.toRoute(targetRoute), scope.use(outTransition), scope.reverse(inTransition));
      }
    });
  }

  exports.default = {
    tabTransitions: tabTransitions
  };
});