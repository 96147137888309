define('ember-select-box/mixins/select-box/select-box/disableable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var isDisabled = this.get('disabled');
      var tabIndex = this.get('tabIndex');

      if (isDisabled) {
        tabIndex = -1;
      }

      this.setProperties({ isDisabled: isDisabled, tabIndex: tabIndex });
    }
  });
});