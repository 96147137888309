define('ember-material-lite/mixins/badge-support', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    badge: null,
    hasBadgeBackground: true,
    attributeBindings: ['badge:data-badge'],
    classNameBindings: ['badge:mdl-badge', 'hasBadgeBackground::mdl-badge--no-background']
  });
});