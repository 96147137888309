define('ember-material-lite/components/mdl-footer-linklist', ['exports', 'ember-material-lite/components/mdl-mini-footer', 'ember-material-lite/components/mdl-mega-footer', 'ember-material-lite/components/-base-child-component', 'ember-composability/mixins/child-component-support', 'ember-material-lite/templates/components/mdl-footer-linklist'], function (exports, _mdlMiniFooter, _mdlMegaFooter, _baseChildComponent, _childComponentSupport, _mdlFooterLinklist) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var A = Ember.A;
  exports.default = _baseChildComponent.default.extend(_childComponentSupport.default, {
    _parentComponentTypes: A([_mdlMegaFooter.default, _mdlMiniFooter.default]),
    layout: _mdlFooterLinklist.default,
    tagName: 'ul',
    childComponentClassName: 'link-list'
  });
});