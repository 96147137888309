define('heating-timeline/mixins/transformable-component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    xScale: 12.5,
    xOffset: 12.5,
    scale: 1,
    offset: 0,
    // Computed
    transform: computed('x', 'scale', 'offset', function () {
      var x = this.get('x'),
          scale = this.get('scale'),
          offset = this.get('offset');

      // dont scale text... shit looks fucked up
      if (this.get('tagName') === 'text') {
        return this.getTranslate(x, scale, offset);
      } else {
        return this.getScale(scale, offset);
      }
    }),
    // Methods
    getTranslate: function getTranslate(x, scale, offset) {
      var translateX = x * scale - x + 12.5 * scale * -offset;
      if (isNaN(translateX)) return '';

      return 'translate(' + translateX + ' 0)';
    },
    getScale: function getScale(scale, offset) {
      var x = -12.5 * offset;
      if (isNaN(x)) return '';

      return 'scale(' + scale + ' 1) translate(' + x + ' 0)';
    }
  });
});