define('ember-sauce-material-design/services/smd-toaster', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    message: null,
    _extraClass: null,
    setMessage: function setMessage(text) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var newExtraClass = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;


      this.set('message', text);

      var snackbarElement = Ember.$('.mdl-snackbar');

      var currentExtraClass = this.get('_extraClass');
      if (currentExtraClass) {
        snackbarElement.removeClass(currentExtraClass);
      }
      if (newExtraClass) {
        snackbarElement.addClass(newExtraClass);
      }
      this.set('_extraClass', newExtraClass);

      var data = {
        message: this.get('message'),
        timeout: 2000
      };
      Ember.merge(data, options);

      snackbarElement.get(0).MaterialSnackbar.showSnackbar(data);
    },
    getMessage: function getMessage() {
      return this.get('message');
    }
  });
});