define('ember-sauce-material-design/components/smd-item-action', ['exports', 'ember-sauce-material-design/templates/components/smd-item-action'], function (exports, _smdItemAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _smdItemAction.default,
    classNameBindings: ['disabledClassModifier'],
    // Attributes
    icon: null,
    label: null,
    action: null,
    disabled: false,
    // Computed
    hasLabel: Ember.computed.bool('label'),
    hasAction: Ember.computed.bool('icon'),
    actionClassNames: Ember.computed('label', function () {
      var classNames = [];
      classNames.push('smd-item__action');
      if (this.get('label')) {
        classNames.push('smd-item__action--labeled');
      }
      return classNames.join(' ');
    }),
    disabledClassModifier: Ember.computed('disabled', function () {
      if (this.get('disabled')) {
        return 'smd-item__action--disabled';
      }
    }),
    // Actions
    actions: {
      buttonAction: function buttonAction() {
        if (this.get('action') && !this.get('disabled')) {
          this.sendAction('action', this);
        }
      }
    }
  });
});