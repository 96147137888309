define('ember-new-computed/utils/can-use-new-syntax', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var supportsSetterGetter;

  try {
    Ember.computed({
      set: function set() {},
      get: function get() {}
    });
    supportsSetterGetter = true;
  } catch (e) {
    supportsSetterGetter = false;
  }

  exports.default = supportsSetterGetter;
});