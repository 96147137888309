define('ember-sauce-material-design/components/smd-page-footer', ['exports', 'ember-sauce-material-design/templates/components/smd-page-footer'], function (exports, _smdPageFooter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      bool = Ember.computed.bool;
  exports.default = Component.extend({
    // Attributes
    layout: _smdPageFooter.default,
    tagName: 'footer',
    classNames: ['smd-page__footer'],
    text: null,
    // Computed
    hasText: bool('text')
  });
});