define('ember-select-box/mixins/select-box/select-box/api', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    api: Ember.computed(function () {
      var _this = this;

      var expose = {};
      ['open', 'close', 'toggle', 'select', 'update', 'selectActiveOption', 'search', 'stopSearching', 'setInputValue', 'focusInput', 'activateOptionAtIndex', 'activateNextOption', 'activatePreviousOption', 'deactivateOptions', 'activateSelectedOptionAtIndex', 'activateNextSelectedOption', 'activatePreviousSelectedOption', 'deactivateSelectedOptions'].forEach(function (actionName) {
        expose[actionName] = Ember.run.bind(_this, _this.get('actions.' + actionName));
      });
      return expose;
    })
  });
});