define('ember-sauce-material-design/components/smd-obfuscator', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // Properties
    tagName: 'div',
    classNames: ['mdl-layout__obfuscator'],
    classNameBindings: ['drawer.visibilityModifier']
  });
});