define('ember-sauce-material-design/components/smd-modal', ['exports', 'ember-sauce-material-design/templates/components/smd-modal'], function (exports, _smdModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      _Ember$computed = Ember.computed,
      bool = _Ember$computed.bool,
      or = _Ember$computed.or;
  exports.default = Component.extend({
    // Attributes
    layout: _smdModal.default,
    tagName: 'dialog',
    classNames: ['smd-modal'],
    classNameBindings: ['hasClose:smd-modal--with-close'],
    action: null,
    title: null,
    actionText: null,
    closeIcon: null,
    primaryText: null,
    secondaryText: null,
    closeAction: null,
    headingAction: null,
    primaryAction: null,
    secondaryAction: null,
    outsideAction: null,
    // Content data to yield
    data: null,
    // Computed
    hasHeader: or('hasTitle', 'hasClose', 'hasAction'),
    hasClose: bool('closeIcon'),
    hasAction: bool('actionText'),
    hasTitle: bool('title'),
    hasPrimary: bool('primaryText'),
    hasSecondary: bool('secondaryText'),
    hasFooter: or('hasPrimary', 'hasSecondary'),
    // Actions
    actions: {
      closeAction: function closeAction() {
        if (this.get('closeAction')) {
          this.sendAction('closeAction', this);
        } else {
          this.sendAction('action', 'close', this);
        }
      },
      headingAction: function headingAction() {
        if (this.get('headingAction')) {
          this.sendAction('headingAction', this);
        } else {
          this.sendAction('action', 'heading', this);
        }
      },
      primaryAction: function primaryAction() {
        if (this.get('primaryAction')) {
          this.sendAction('primaryAction', this);
        } else {
          this.sendAction('action', 'primary', this);
        }
      },
      secondaryAction: function secondaryAction() {
        if (this.get('secondaryAction')) {
          this.sendAction('secondaryAction', this);
        } else {
          this.sendAction('action', 'secondary', this);
        }
      },
      outsideAction: function outsideAction() {
        if (this.get('outsideAction')) {
          this.sendAction('outsideAction', this);
        } else {
          this.sendAction('action', 'outside', this);
        }
      }
    },
    didInsertElement: function didInsertElement() {
      var modal = this.element;
      modal.showModal();
    },
    keyDown: function keyDown(e) {
      e.preventDefault();
      // esc
      if (e.keyCode === 27) {
        this.send('closeAction');
      }
    }
  });
});