define('ember-select-box/mixins/select-box/select-box/inputtable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    _overrideTabIndex: function _overrideTabIndex() {
      this.set('tabIndex', -1);
    },


    actions: {
      inputText: function inputText() {},
      _registerInput: function _registerInput() {
        this._super.apply(this, arguments);
        Ember.run.scheduleOnce('afterRender', this, '_overrideTabIndex');
      }
    }
  });
});