define('ember-sauce-material-design/components/smd-search-widget', ['exports', 'ember-sauce-material-design/templates/components/smd-search-widget'], function (exports, _smdSearchWidget) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _smdSearchWidget.default,
    tagName: 'div',
    classNames: ['smd-search-widget'],
    classNameBindings: ['openClass'],
    action: null,
    isOpen: false,
    term: null,
    searchParam: null,
    // Computed
    openClass: Ember.computed('isOpen', function () {
      if (this.get('isOpen')) {
        return 'open';
      }
    }),
    didRender: function didRender() {
      if (this.get('term')) {
        this.set('isOpen', true);
        this.$('.smd-search-widget__input').focus();
      }
    },
    actions: {
      open: function open() {
        this.set('isOpen', true);
        setTimeout(function () {
          this.$('.smd-search-widget__input').focus();
        }, 50);
        return false;
      },
      close: function close() {
        this.set('isOpen', false);
        this.set('term', null);
        this.sendAction('action', null);
        return false;
      },
      keyUp: function keyUp(term) {
        this.sendAction('action', term);
      }
    }
  });
});