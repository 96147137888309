define('ember-material-lite/components/mdl-table-col', ['exports', 'ember-material-lite/components/-base-child-component', 'ember-composability/mixins/child-component-support', 'ember-material-lite/components/mdl-table', 'ember-material-lite/templates/components/mdl-table-col'], function (exports, _baseChildComponent, _childComponentSupport, _mdlTable, _mdlTableCol) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isEmpty = Ember.isEmpty,
      A = Ember.A;
  exports.default = _baseChildComponent.default.extend(_childComponentSupport.default, {
    _parentComponentTypes: new A([_mdlTable.default]),
    tagName: 'td',
    layout: _mdlTableCol.default,
    clssNameBindings: ['isNonNumeric:mdl-data-table__cell--non-numeric'],
    shouldRegisterToParent: function shouldRegisterToParent(parentComponent) {
      var childComponents = parentComponent.getComposableChildren();
      if (isEmpty(childComponents)) {
        return true;
      } else {
        var labels = parentComponent.getComposableChildren().map(function (x) {
          return x.label;
        });
        return labels.indexOf(this.get('label')) < 0;
      }
    }
  });
});