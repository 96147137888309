define('ember-material-lite/components/mdl-table', ['exports', 'ember-material-lite/components/-base-toplevel-component', 'ember-composability/mixins/parent-component-support', 'ember-material-lite/mixins/shadow-support', 'ember-material-lite/templates/components/mdl-table'], function (exports, _baseToplevelComponent, _parentComponentSupport, _shadowSupport, _mdlTable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseToplevelComponent.default.extend(_parentComponentSupport.default, _shadowSupport.default, {
    primaryClassName: 'data-table',
    tagName: 'table',
    composableChildrenDebounceTime: 1,
    layout: _mdlTable.default,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var mdltable = new window.MaterialDataTable(this.get('element'));
      this.set('_mdlComponent', mdltable);
    }
  });
});