define('ember-material-lite/components/mdl-spinner', ['exports', 'ember-material-lite/components/-base-toplevel-component', 'ember-material-lite/templates/components/mdl-spinner'], function (exports, _baseToplevelComponent, _mdlSpinner) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseToplevelComponent.default.extend({
    primaryClassName: 'spinner',
    classNameBindings: ['isActive:is-active', 'isSingleColor:mdl-spinner--single-color'],
    _mdlComponent: null,
    isActive: true,
    isSingleColor: false,
    layout: _mdlSpinner.default,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var mdlspinner = new window.MaterialSpinner(this.get('element'));
      this.set('_mdlComponent', mdlspinner);
    }
  });
});