define('ember-material-lite/components/mdl-nav-item', ['exports', 'ember-composability/mixins/child-component-support', 'ember-material-lite/mixins/click-action-support', 'ember-material-lite/components/mdl-nav'], function (exports, _childComponentSupport, _clickActionSupport, _mdlNav) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      A = Ember.A;
  exports.default = Component.extend(_childComponentSupport.default, _clickActionSupport.default, {
    _parentComponentTypes: A([_mdlNav.default]),
    inDrawer: true,
    inHeader: true
  });
});