define("ember-sauce-material-design/mixins/smd-fabable-route", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin,
      service = Ember.inject.service;
  exports.default = Mixin.create({
    // Services
    fab: service("smd-fab-service"),
    // Attributes
    fabIcon: "add",
    fabAlign: "right",
    fabIsSmall: false,
    fabIsAccent: false,
    fabIsPrimary: true,
    fabDisabled: false,
    fabIsHidden: false,
    fabAction: "fabAction",
    // Methods
    activate: function activate() {
      this._super();
      this.get("fab").setup({
        icon: this.get("fabIcon"),
        align: this.get("fabAlign"),
        isSmall: this.get("fabIsSmall"),
        isAccent: this.get("fabIsAccent"),
        isPrimary: this.get("fabIsPrimary"),
        disabled: this.get("fabDisabled"),
        isHidden: this.get("fabIsHidden"),
        action: this.get("fabAction")
      });
    },
    deactivate: function deactivate() {
      this._super();
      this.get("fab").clear();
    }
  });
});