define('ember-sauce-material-design/mixins/badge-support', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    badge: null,
    noBadgeBackground: false,
    badgeOverlap: false,
    attributeBindings: ['badge:data-badge'],
    classNameBindings: ['badge:mdl-badge', 'noBadgeBackground:mdl-badge--no-background', 'badgeOverlap:mdl-badge--overlap']
  });
});