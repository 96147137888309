define('ember-material-lite/components/-base-input-component', ['exports', 'ember-material-lite/components/-base-toplevel-component'], function (exports, _baseToplevelComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      isPresent = Ember.isPresent,
      observer = Ember.observer,
      run = Ember.run;
  exports.default = _baseToplevelComponent.default.extend({
    primaryClassName: 'textfield',
    type: 'text',
    disabled: false,
    isLabelFloating: true,
    errorMessage: null,
    classNameBindings: ['isLabelFloating:mdl-textfield--floating-label'],
    // This should never change, so we deliberately
    //   declare no dependent property keys
    _inputId: computed(function () {
      return this.get('elementId') + '-input';
    }),
    beforeMdlInit: function beforeMdlInit() {},
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.beforeMdlInit();
      var mdlTextfield = new window.MaterialTextfield(this.get('element'));
      this.set('_mdlComponent', mdlTextfield);
    },

    _checkValidity: observer('errorMessage', function () {
      run.scheduleOnce('afterRender', this, this._setValidity);
    }),
    _setValidity: function _setValidity() {
      if (this.isDestroyed) {
        return;
      }

      var mdlComponent = this.get('_mdlComponent');
      var errorMessage = this.get('errorMessage');

      if (isPresent(errorMessage)) {
        mdlComponent.input_.setCustomValidity(errorMessage.toString());
      } else {
        mdlComponent.input_.setCustomValidity('');
      }
    }
  });
});