define('ember-material-lite/components/mdl-footer-dropdown-section', ['exports', 'ember-material-lite/templates/components/mdl-footer-dropdown-section', 'ember-material-lite/components/mdl-mini-footer', 'ember-material-lite/components/mdl-mega-footer', 'ember-composability/mixins/child-component-support'], function (exports, _mdlFooterDropdownSection, _mdlMiniFooter, _mdlMegaFooter, _childComponentSupport) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      A = Ember.A;
  exports.default = Component.extend(_childComponentSupport.default, {
    _parentComponentTypes: A([_mdlMegaFooter.default, _mdlMiniFooter.default]),
    layout: _mdlFooterDropdownSection.default,
    childComponentClassName: 'drop-down-section'
  });
});