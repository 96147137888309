define('ember-select-box/mixins/select-box/general/styleable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var escapeExpression = Ember.Handlebars.Utils.escapeExpression;
  exports.default = Ember.Mixin.create({
    attributeBindings: ['customCSS:style'],

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this._updateStyle();
    },
    _updateStyle: function _updateStyle() {
      var style = this.get('style');
      if (!style) {
        return;
      }
      var css = Ember.String.htmlSafe(escapeExpression(style));
      this.set('customCSS', css);
    }
  });
});