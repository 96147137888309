define('ember-select-box/mixins/select-box/registration/has-selected-options-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    actions: {
      _registerSelectedOptionsContainer: function _registerSelectedOptionsContainer(container) {
        (true && !(!this.get('selectedOptionsContainer')) && Ember.assert('A select box can only have 1 selected options container', !this.get('selectedOptionsContainer')));

        this.set('selectedOptionsContainer', container);
      },
      _deregisterSelectedOptionsContainer: function _deregisterSelectedOptionsContainer() {
        this.set('selectedOptionsContainer', null);
      }
    }
  });
});