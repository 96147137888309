define('ember-sauce-material-design/services/smd-app-state', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    isDrawerVisible: false,
    drawerVisibilityModifier: Ember.computed('isDrawerVisible', function () {
      if (this.get('isDrawerVisible')) {
        return 'is-visible';
      } else {
        return 'is-hidden';
      }
    })
  });
});