define('ember-select-box/utils/try-set', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = trySet;


  /**
   * Attempts to set a value on an object.
   * Silently fails if the object has been destroyed.
   * Waiting for this issue to be resolved:
   * https://github.com/emberjs/ember.js/issues/12356
   */
  function trySet(object, key, value) {
    if (!Ember.get(object, 'isDestroyed')) {
      Ember.set(object, key, value);
    }
  }
});