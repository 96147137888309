define('ember-material-lite/components/mdl-progress', ['exports', 'ember-material-lite/components/-base-toplevel-component', 'ember-material-lite/templates/components/mdl-progress'], function (exports, _baseToplevelComponent, _mdlProgress) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var empty = Ember.computed.empty,
      computed = Ember.computed;
  exports.default = _baseToplevelComponent.default.extend({
    primaryClassName: 'progress',
    classNameBindings: ['indeterminate:mdl-progress__indeterminate'],
    _progress: null,
    _buffer: null,
    _mdlComponent: null,

    indeterminate: empty('progress'),
    layout: _mdlProgress.default,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var mdlprogress = new window.MaterialProgress(this.get('element'));
      this.set('_mdlComponent', mdlprogress);
      this._updateProgress();
      this._updateBuffer();
    },
    _updateProgress: function _updateProgress() {
      var progress = this.get('progress');
      if (progress !== null) {
        this.get('_mdlComponent').setProgress(progress);
      }
    },
    _updateBuffer: function _updateBuffer() {
      var buffer = this.get('buffer');
      if (buffer !== null) {
        this.get('_mdlComponent').setBuffer(buffer);
      }
    },


    progress: computed('_progress', {
      get: function get() {
        return this.get('_progress');
      },
      set: function set(key, newVal) {
        if (this.element) {
          this._updateProgress();
        }
        this.set('_progress', newVal);
        return newVal;
      }
    }),

    buffer: computed('_buffer', {
      get: function get() {
        return this.get('_buffer');
      },
      set: function set(key, newVal) {
        if (this.element) {
          this._updateBuffer();
        }
        this.set('_buffer', newVal);
        return newVal;
      }
    })
  });
});