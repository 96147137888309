define('ember-select-box/mixins/select-box/registration/has-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    actions: {
      _registerInput: function _registerInput(input) {
        if (this.get('input')) {
          (true && !(!this.get('input')) && Ember.assert('select-box can only have 1 input', !this.get('input')));
        }
        this.set('input', input);
      },
      _deregisterInput: function _deregisterInput() {
        this.set('input', null);
      }
    }
  });
});