define('ember-material-lite/components/mdl-textfield', ['exports', 'ember-material-lite/components/-base-input-component', 'ember-material-lite/templates/components/mdl-textfield'], function (exports, _baseInputComponent, _mdlTextfield) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseInputComponent.default.extend({
    pattern: null,
    isExpandable: false,
    expandableIcon: null,
    layout: _mdlTextfield.default,
    classNameBindings: ['isExpandable:mdl-textfield--expandable'],
    beforeMdlInit: function beforeMdlInit() {
      this.$('label.mdl-button').attr('for', this.get('_inputId'));
    }
  });
});