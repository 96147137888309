define("ember-string-ishtmlsafe-polyfill/index", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  Ember.deprecate("ember-string-ishtmlsafe-polyfill is now a true polyfill. Use Ember.String.isHTMLSafe directly instead of importing from ember-string-ishtmlsafe-polyfill", false, {
    id: "ember-string-ishtmlsafe-polyfill.import",
    until: '2.0.0'
  });

  exports.default = Ember.String.isHTMLSafe;
});