define('ember-material-lite/components/mdl-menu', ['exports', 'ember-composability/mixins/parent-component-support', 'ember-material-lite/templates/components/mdl-menu'], function (exports, _parentComponentSupport, _mdlMenu) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend(_parentComponentSupport.default, {
    layout: _mdlMenu.default,
    _primaryClassNameString: 'mdl-menu',
    icon: 'more_vert',
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var mdlmenu = new window.MaterialMenu(this.$('ul')[0]);
      this.set('_mdlComponent', mdlmenu);
    },

    _buttonId: computed('elementId', {
      get: function get() {
        return this.get('elementId') + '-button';
      }
    }),
    align: 'top-left',
    _alignClass: computed('align', {
      get: function get() {
        return 'mdl-menu--' + this.get('align');
      }
    })
  });
});