define('ember-material-lite/components/mdl-slider', ['exports', 'ember-material-lite/components/-base-toplevel-component', 'ember-material-lite/templates/components/mdl-slider'], function (exports, _baseToplevelComponent, _mdlSlider) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseToplevelComponent.default.extend({
    tagName: 'input',
    type: 'range',
    min: 0,
    max: 100,
    value: 0,
    attributeBindings: ['type', 'min', 'max', 'value'],
    primaryClassName: 'slider',
    layout: _mdlSlider.default,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var mdlslider = new window.MaterialSlider(this.get('element'));
      this.set('_mdlComponent', mdlslider);
    }
  });
});