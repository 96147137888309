define('heating-timeline/components/period-button', ['exports', 'heating-timeline/templates/components/period-button', 'heating-timeline/mixins/transformable-component'], function (exports, _periodButton, _transformableComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend(_transformableComponent.default, {
    layout: _periodButton.default,
    // Attributes
    attributeBindings: ['x', 'y', 'width', 'height', 'transform'],
    classNames: 'schedule__button',
    height: 24,
    interval: 12.5,
    offset: 0,
    tagName: 'text',
    viewbox: '0 0 24 24',
    scale: 1,
    y: -78,
    width: 24,
    // Computed
    x: computed('model.start', 'model.end', 'width', 'interval', function () {
      if (this.get('model') == null) return;

      var interval = this.get('interval'),
          x = this.get('model.start') * interval,
          areaWidth = (this.get('model.end') - this.get('model.start')) * interval;

      return x + areaWidth / 2 - this.get('width') / 2;
    }),
    // Events
    click: function click() {
      this.sendAction('action', this.get('model'));
    }
  });
});