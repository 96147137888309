define('ember-select-box/helpers/select-box-class', ['exports', 'ember-select-box/utils/select-box/class-name'], function (exports, _className) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var escapeExpression = Ember.Handlebars.Utils.escapeExpression;
  exports.default = Ember.Helper.helper(function () {
    var args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

    var string = _className.default.apply(undefined, _toConsumableArray(args));
    string = escapeExpression(string);
    return Ember.String.htmlSafe(string);
  });
});