define('ember-material-lite/components/mdl-icon-toggle', ['exports', 'ember-material-lite/components/-base-toggle-component', 'ember-material-lite/templates/components/mdl-icon-toggle'], function (exports, _baseToggleComponent, _mdlIconToggle) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseToggleComponent.default.extend({
    primaryClassName: 'icon-toggle',
    layout: _mdlIconToggle.default,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var mdlicontoggle = new window.MaterialIconToggle(this.get('element'));
      this.set('_mdlComponent', mdlicontoggle);
    }
  });
});