define('ember-select-box/mixins/select-box/select-box/toggleable', ['exports', 'ember-select-box/utils/try-set', 'ember-select-box/utils/invoke-action'], function (exports, _trySet, _invokeAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    isOpen: false,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var open = this.get('is-open');
      if (Ember.isPresent(open)) {
        this.set('isOpen', open);
      }
    },


    actions: {
      open: function open() {
        this.set('isOpen', true);
        (0, _invokeAction.default)(this, 'on-open', this.get('api'));
      },
      close: function close() {
        var _this = this;

        Ember.run(function () {
          return (0, _trySet.default)(_this, 'isClosing', true);
        });
        Ember.run(function () {
          return (0, _trySet.default)(_this, 'isOpen', false);
        });
        Ember.run(function () {
          return (0, _trySet.default)(_this, 'isClosing', false);
        });
        (0, _invokeAction.default)(this, 'on-close', this.get('api'));
      },
      toggle: function toggle() {
        if (this.get('isOpen')) {
          this.send('close');
        } else {
          this.send('open');
        }
      }
    }
  });
});