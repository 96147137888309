define('ember-select-box/mixins/select-box/select-box/select-active-option', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    actions: {
      selectActiveOption: function selectActiveOption() {
        var activeOption = this.get('activeOption');
        if (activeOption) {
          activeOption.send('select');
        }
      }
    }
  });
});