define('ember-material-lite/components/mdl-switch', ['exports', 'ember-material-lite/components/-base-toggle-component', 'ember-material-lite/templates/components/mdl-switch'], function (exports, _baseToggleComponent, _mdlSwitch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseToggleComponent.default.extend({
    primaryClassName: 'switch',
    layout: _mdlSwitch.default,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var mdlswitch = new window.MaterialSwitch(this.get('element'));
      this.set('_mdlComponent', mdlswitch);
    }
  });
});