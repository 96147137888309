define('ember-material-lite/components/mdl-card-buttons', ['exports', 'ember-material-lite/templates/components/mdl-card-buttons'], function (exports, _mdlCardButtons) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['mdl-card__menu'],
    layout: _mdlCardButtons.default
  });
});