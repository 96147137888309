define('ember-sauce-material-design/components/smd-fab', ['exports', 'ember-sauce-material-design/components/smd-button', 'ember-sauce-material-design/templates/components/smd-button'], function (exports, _smdButton, _smdButton2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      alias = Ember.computed.alias,
      service = Ember.inject.service;
  exports.default = _smdButton.default.extend({
    // Services
    service: service('smd-fab-service'),
    // Attributes
    layout: _smdButton2.default,
    classNames: ["smd-fab"],
    classNameBindings: ["alignClass", "isMiniFab:smd-fab--mini", "isHidden:smd-fab--hidden"],
    isFab: true,
    // Computed
    action: alias("service.action"),
    icon: alias("service.icon"),
    align: alias("service.align"),
    isHidden: alias("service.isHidden"),
    isMiniFab: alias("service.isSmall"),
    isAccent: alias("service.isAccent"),
    isPrimary: alias("service.isPrimary"),
    disabled: alias("service.disabled"),
    alignClass: computed("align", function () {
      if (this.get("align") === "right") {
        return "smd-fab--right";
      }
      if (this.get("align") === "left") {
        return "smd-fab--left";
      }
    })
  });
});