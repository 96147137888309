define('ember-material-lite/components/mdl-tab', ['exports', 'ember-material-lite/components/mdl-tabs', 'ember-composability/mixins/child-component-support', 'ember-material-lite/templates/components/mdl-tab'], function (exports, _mdlTabs, _childComponentSupport, _mdlTab) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      dasherize = Ember.String.dasherize,
      A = Ember.A;
  exports.default = Component.extend(_childComponentSupport.default, {
    layout: _mdlTab.default,
    _parentComponentTypes: new A([_mdlTabs.default]),
    classNames: ['mdl-tabs__panel'],
    classNameBindings: ['isActive:is-active'],
    isActive: false,
    dasherizedTitle: computed('title', function () {
      return dasherize(this.get('title'));
    })
  });
});