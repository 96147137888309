define('heating-timeline/components/period-area', ['exports', 'heating-timeline/templates/components/period-area', 'heating-timeline/mixins/transformable-component'], function (exports, _periodArea, _transformableComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Component = Ember.Component;
  exports.default = Component.extend(_transformableComponent.default, {
    layout: _periodArea.default,
    // Attributes
    attributeBindings: ['x', 'y', 'width', 'height', 'transform'],
    classNames: ['schedule__area'],
    classNameBindings: ['feelsClass'],
    empty: false,
    height: 180,
    interval: 12.5,
    model: null, // instance of period model
    offset: 0,
    tagName: 'rect',
    scale: 1,
    y: -180,
    // Computed
    feelsClass: computed('model.temp', 'empty', function () {
      if (this.get('model') == null) return;

      var baseClass = 'schedule__area',
          temp = void 0;

      if (this.get('empty')) {
        return baseClass + '--empty';
      }

      temp = this.get('model.temp');

      if (temp > 25) {
        return baseClass + '--hot';
      }
      if (temp > 19) {
        return baseClass + '--warm';
      }
      return baseClass + '--cool';
    }),
    width: computed('model.start', 'model.end', 'interval', function () {
      if (this.get('model') == null) return;

      var interval = this.get('interval');
      return (this.get('model.end') - this.get('model.start')) * interval;
    }),
    x: computed('model.start', 'interval', function () {
      if (this.get('model') == null) return;

      var interval = this.get('interval');
      return this.get('model.start') * interval;
    }),
    // Events
    click: function click() {
      this.sendAction('action', this.get('model'));
    }
  });
});