define('ember-sauce-material-design/components/smd-badge', ['exports', 'ember-sauce-material-design/templates/components/smd-badge', 'ember-sauce-material-design/mixins/badge-support'], function (exports, _smdBadge, _badgeSupport) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend(_badgeSupport.default, {
    layout: _smdBadge.default,
    text: null,
    tagName: 'span'
  });
});