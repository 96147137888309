define('ember-sauce-material-design/components/smd-page', ['exports', 'ember-sauce-material-design/templates/components/smd-page'], function (exports, _smdPage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _smdPage.default,
    classNames: ['smd-page']
  });
});