define('ember-sauce-material-design/components/smd-icon', ['exports', 'ember-sauce-material-design/templates/components/smd-icon', 'ember-sauce-material-design/mixins/badge-support'], function (exports, _smdIcon, _badgeSupport) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend(_badgeSupport.default, {
    classNames: ['icon', 'material-icons', 'smd-icon'],
    layout: _smdIcon.default
  });
});