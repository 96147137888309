define('ember-sauce-material-design/helpers/smd-subheader', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    state: Ember.inject.service('smd-subheader-state'),
    compute: function compute(params) {
      var subheader = params[0];
      var className = '';
      if (params.length > 1) {
        className = params[1];
      }
      if (this.get('state.subheader') !== subheader) {
        this.set('state.subheader', subheader);
        return Ember.String.htmlSafe('<h2 class="smd-subheader smd-subheader--bordered ' + className + '">' + subheader + '</h2>');
      }
    }
  });
});