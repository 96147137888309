define('ember-sauce-material-design/components/smd-data-list', ['exports', 'ember-sauce-material-design/templates/components/smd-data-list'], function (exports, _smdDataList) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _smdDataList.default,
    classNames: ['smd-data-list']
  });
});