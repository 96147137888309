define('ember-inline-svg/helpers/inline-svg', ['exports', 'ember-inline-svg/utils/general'], function (exports, _general) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.inlineSvg = inlineSvg;
  function inlineSvg(svgs, path, options) {
    var jsonPath = (0, _general.dottify)(path);
    var svg = Ember.get(svgs, jsonPath);

    // TODO: Ember.get should return `null`, not `undefined`.
    // if (svg === null && /\.svg$/.test(path))
    if (typeof svg === "undefined" && /\.svg$/.test(path)) {
      svg = Ember.get(svgs, jsonPath.slice(0, -4));
    }

    Ember.assert("No SVG found for " + path, svg);

    svg = (0, _general.applyClass)(svg, options.class);

    return Ember.String.htmlSafe(svg);
  }
});