define('ember-select-box/components/select-box/group', ['exports', 'ember-select-box/mixins/select-box/general/nameable', 'ember-select-box/templates/components/select-box/group'], function (exports, _nameable, _group) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_nameable.default, {
    layout: _group.default,
    classNameSuffix: 'group'
  });
});