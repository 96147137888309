define('ember-sauce-material-design/components/smd-navigation', ['exports', 'ember-sauce-material-design/templates/components/smd-navigation'], function (exports, _smdNavigation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _smdNavigation.default,
    classNames: ['mdl-navigation'],
    tagName: 'navigation'
  });
});