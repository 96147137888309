define('ember-sauce-material-design/components/smd-dropdown', ['exports', 'ember-sauce-material-design/templates/components/smd-dropdown'], function (exports, _smdDropdown) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      bool = Ember.computed.bool;
  exports.default = Component.extend({
    layout: _smdDropdown.default,
    classNames: ['smd-dropdown'],
    text: null,
    icon: null,
    disabled: false,
    align: 'bottom-left',
    // Computed
    hasText: bool('text'),
    hasIcon: bool('icon'),
    alignClass: computed('align', function () {
      return 'mdl-menu--' + this.get('align');
    }),
    buttonId: computed('elementId', function () {
      return this.get('elementId') + '-button';
    }),
    // Methods
    didInsertElement: function didInsertElement() {
      // add dropdown and child items to the mdl component registry
      componentHandler.upgradeElements(this.element);
    }
  });
});