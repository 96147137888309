define('ember-sauce-material-design/components/smd-data-item', ['exports', 'ember-sauce-material-design/templates/components/smd-data-item', 'ember-sauce-material-design/mixins/click-support'], function (exports, _smdDataItem, _clickSupport) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      bool = Ember.computed.bool;
  exports.default = Component.extend(_clickSupport.default, {
    // Attributes
    layout: _smdDataItem.default,
    classNames: ['smd-data-list__item', 'smd-data-list__value'],
    classNameBindings: ['hasAction:smd-data-list__value--with-action', 'isMultiLine:smd-data-list__value--multi-line', 'isSmall:smd-data-list__value--small'],
    label: null,
    url: null,
    value: null,
    icon: null,
    isMultiLine: false,
    isSmall: false,
    // Computed
    hasAction: bool('action'),
    hasLabel: bool('label'),
    hasValue: bool('value'),
    hasUrl: bool('url'),
    hasIcon: bool('icon'),
    niceUrl: computed('url', function () {
      return this.get('url').replace(/(^\w+:|^)\/\//, '');
    })
  });
});