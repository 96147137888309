define('ember-select-box/mixins/select-box/option/selectable', ['exports', 'ember-select-box/utils/invoke-action'], function (exports, _invokeAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.set('manualSelection', this.get('selected'));
      this.set('selectedValue', this.get('-selected-value'));
      this.set('isMultiple', this.get('-multiple'));
    },


    isSelected: Ember.computed('value', 'selectedValue', 'isMultiple', 'manualSelection', function () {
      if (this.get('manualSelection') !== undefined) {
        return this.get('manualSelection');
      } else if (this.get('isMultiple')) {
        return Ember.A(this.get('selectedValue')).includes(this.get('value'));
      } else {
        return this.get('value') === this.get('selectedValue');
      }
    }),

    actions: {
      select: function select() {
        this._super.apply(this, arguments);
        if (!this.get('isDisabled')) {
          (0, _invokeAction.default)(this, '-select', this.get('value'));
        }
      }
    }
  });
});