define('ember-sauce-material-design/mixins/smd-stackable-header-route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin,
      service = Ember.inject.service;
  exports.default = Mixin.create({
    headerStack: service('smd-header-stack-service'),
    headerProps: {},
    activate: function activate() {
      Ember.Logger.log('activate ' + this.routeName);
      this.get('headerStack').push(this.get('headerProps'));
      return this._super.apply(this, arguments);
    },
    deactivate: function deactivate() {
      Ember.Logger.log('deactivate ' + this.routeName);
      this.get('headerStack').pop();
      return this._super.apply(this, arguments);
    }
  });
});