define('ember-sauce-material-design/components/smd-toolbar-item', ['exports', 'ember-sauce-material-design/templates/components/smd-toolbar-item'], function (exports, _smdToolbarItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      bool = Ember.computed.bool;
  exports.default = Component.extend({
    // Attributes
    layout: _smdToolbarItem.default,
    align: 'bottom-right',
    options: [],
    selectedOption: null,
    icon: null,
    // Computed
    isDropdown: bool('options.length'),
    items: computed('options', 'sort', function () {
      var _this = this;

      var items = [];
      this.get('options').forEach(function (option) {
        items.push({
          text: option.text,
          value: option.value,
          icon: _this.get('selectedOption') === option.value ? 'check' : 'none'
        });
      });
      return items;
    }),
    // Actions
    actions: {
      itemAction: function itemAction(button) {
        this.sendAction('action', button, this);
      }
    }
  });
});