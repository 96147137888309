define('heating-timeline/components/x-axis', ['exports', 'heating-timeline/templates/components/x-axis', 'heating-timeline/mixins/transformable-component'], function (exports, _xAxis, _transformableComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Component = Ember.Component;
  exports.default = Component.extend(_transformableComponent.default, {
    layout: _xAxis.default,
    interval: 12.5,
    scale: 1,
    tagName: 'g',
    width: 300,
    // Computed
    lines: computed('interval', 'scale', function () {
      var ticks = [];

      var interval = this.get('interval'),
          scale = this.get('scale'),
          offset = this.get('offset');

      for (var i = 0; i <= 24; i++) {

        var x = i * interval,
            y = 10,
            transform = this.getTranslate(x, scale, offset),
            every;

        if (scale >= 4) {
          every = 2;
        } else if (scale >= 2) {
          every = 4;
        } else {
          every = 6;
        }
        // tall ticks every 'interval' hours
        if (i % every === 0) {
          y = 15;
        }

        ticks.push({
          x1: x,
          x2: x,
          y1: 5,
          y2: y,
          transform: transform
        });
      }
      return ticks;
    }),
    labels: computed('interval', 'scale', function () {
      var labels = [];

      var interval = this.get('interval'),
          scale = this.get('scale'),
          offset = this.get('offset');

      for (var i = 0; i <= 24; i++) {
        var x = i * interval,
            y = 20,
            transform = this.getTranslate(x, scale, offset),
            every = void 0;

        var label = {
          x: x,
          y: y,
          text: i + ":00",
          transform: transform
        };

        if (scale >= 4) {
          every = 2;
        } else if (scale >= 2) {
          every = 4;
        } else {
          every = 6;
        }

        // labels every 6 hours
        if (i % every === 0) {
          label.opacity = 1.0;
        } else {
          label.opacity = 0.0;
        }

        labels.push(label);
      }
      return labels;
    })
  });
});