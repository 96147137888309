define('ember-select-box/mixins/select-box/registration/has-selected-options', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('_selectedOptions', Ember.A());
      this.set('selectedOptions', Ember.A());
    },
    _scheduleUpdateSelectedOptions: function _scheduleUpdateSelectedOptions() {
      Ember.run.scheduleOnce('afterRender', this, '_updateSelectedOptions');
    },
    _updateSelectedOptions: function _updateSelectedOptions() {
      this.set('selectedOptions', Ember.A(this.get('_selectedOptions').toArray()));
    },


    actions: {
      _registerSelectedOption: function _registerSelectedOption(selectedOption) {
        this.get('_selectedOptions').addObject(selectedOption);
        this._scheduleUpdateSelectedOptions();
      },
      _deregisterSelectedOption: function _deregisterSelectedOption(selectedOption) {
        this.get('_selectedOptions').removeObject(selectedOption);
        this._scheduleUpdateSelectedOptions();
      }
    }
  });
});