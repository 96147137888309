define('ember-select-box/components/select-box/native', ['exports', 'ember-select-box/mixins/select-box/base', 'ember-select-box/templates/components/select-box/native'], function (exports, _base, _native) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_base.default, {
    layout: _native.default,
    tagName: 'select',
    attributeBindings: ['name', 'title', 'tabindex', 'disabled', 'size', 'multiple', 'autofocus', 'required', 'aria-label'],

    change: function change() {
      var registeredSelected = this._getRegisteredSelectedValues();
      var unregisteredSelected = this._getUnregisteredSelectedValues();

      var selectedValues = void 0;

      if (registeredSelected.length > 0) {
        selectedValues = registeredSelected;
      } else {
        selectedValues = unregisteredSelected;
      }

      if (this.get('multiple')) {
        this.send('select', selectedValues);
      } else {
        this.send('select', selectedValues[0]);
      }
    },
    _getRegisteredSelectedValues: function _getRegisteredSelectedValues() {
      return Ember.A(this.get('options').filter(function (option) {
        return option.get('element.selected');
      }).map(function (option) {
        return option.get('value');
      }));
    },
    _getUnregisteredSelectedValues: function _getUnregisteredSelectedValues() {
      return Ember.A([].slice.call(this.get('element').querySelectorAll('option:checked')).map(function (option) {
        return option.value;
      }));
    }
  });
});