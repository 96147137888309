define('ember-select-box/mixins/select-box/general/click-outside', ['exports', 'ember-select-box/mixins/select-box/general/document-click'], function (exports, _documentClick) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_documentClick.default, {
    clickDocument: function clickDocument(e) {
      this._super.apply(this, arguments);
      var el = this.get('element');
      var clickedSelf = el === e.target;
      var clickedInside = Ember.$.contains(el, e.target);
      var clickedOutside = !clickedSelf && !clickedInside;
      if (clickedOutside) {
        this.clickOutside();
      }
    },
    clickOutside: function clickOutside() {}
  });
});