define('ember-sauce-material-design/services/smd-fab-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var copy = Ember.copy,
      merge = Ember.merge,
      Service = Ember.Service;
  exports.default = Service.extend({
    icon: null,
    align: null,
    isHidden: true,
    isSmall: null,
    isAccent: null,
    isPrimary: null,
    disabled: null,
    action: null,
    // Methods
    clear: function clear() {
      var _this = this;

      var defaults = copy(this.get('_defaults'));
      Object.keys(defaults).forEach(function (key) {
        _this.set(key, defaults[key]);
      });
    },
    setup: function setup(hash) {
      var _this2 = this;

      var defaults = copy(this.get('_defaults'));
      merge(defaults, hash);
      Object.keys(defaults).forEach(function (key) {
        _this2.set(key, defaults[key]);
      });
    },
    // Private Attributes
    _defaults: {
      icon: "add",
      align: "right",
      isHidden: true,
      isSmall: false,
      isAccent: false,
      isPrimary: true,
      disabled: false,
      action: "fabAction"
    }
  });
});