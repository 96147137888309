define('ember-select-box/mixins/select-box/registration/has-options', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('_options', Ember.A());
      this.set('options', Ember.A());
    },
    _scheduleUpdateOptions: function _scheduleUpdateOptions() {
      Ember.run.scheduleOnce('afterRender', this, '_updateOptions');
    },
    _updateOptions: function _updateOptions() {
      this.set('options', Ember.A(this.get('_options').toArray()));
    },


    actions: {
      _registerOption: function _registerOption(option) {
        this.get('_options').addObject(option);
        this._scheduleUpdateOptions();
      },
      _deregisterOption: function _deregisterOption(option) {
        this.get('_options').removeObject(option);
        this._scheduleUpdateOptions();
      }
    }
  });
});