define('heating-timeline/components/heating-timeline', ['exports', 'heating-timeline/templates/components/heating-timeline'], function (exports, _heatingTimeline) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Component.extend({
    layout: _heatingTimeline.default,
    // Services
    timeline: service('timeline'),
    // Attributes
    attributeBindings: ['version', 'xmlns', 'viewBox', 'preserveAspectRatio'],
    classNames: 'schedule',
    classNameBindings: ['expandedClass'],
    emptyPeriodSelected: null, // action called
    minimized: false,
    showAxis: true,
    offset: computed.alias('timeline.offset'),
    panDisabled: false,
    zoomDisabled: false,
    panOffset: 0, // Set when we begin a pan to remember original offset
    periodSelected: null, // action called
    preserveAspectRatio: 'xMidYMid meet',
    scale: computed.alias('timeline.scale'),
    tagName: 'svg',
    touchStatus: false,
    version: "1.1",
    xmlns: "http://www.w3.org/2000/svg",
    model: null, // array of ember objects representing heating periods
    // Computed
    periods: computed.alias('model'),
    newPeriods: computed('periods.@each', function () {
      //Ember.Logger.log('=== computed periods');
      var periods = this.get('periods');
      return this.getInverseTimePeriods(periods);
    }),
    viewBox: computed('minimized', 'showAxis', function () {
      var height;
      if (this.get('minimized')) {
        height = this.get('showAxis') ? 50 : 20;
        return '-12.5 -20 325 ' + height;
      }
      height = this.get('showAxis') ? 205 : 185;
      return '-12.5 -180 325 ' + height;
    }),
    // Actions
    actions: {
      periodSelected: function periodSelected(period) {
        //Ember.Logger.log('periodSelected');
        Ember.Logger.log('periodSelected');
        if (!this.get('zoomDisabled')) {
          Ember.Logger.log('periodSelected');
          this.send('goTo', period.get('start'), period.get('end'));
        }
        this.get('timeline').set('activePeriod', period);

        var action = this.get('onPeriodSelect');
        if (action) {
          return action(period);
        }
        this.sendAction('periodSelected', period);
      },
      emptyPeriodSelected: function emptyPeriodSelected(period) {
        //Ember.Logger.log('emptyPeriodSelected');
        if (!this.get('enableNewPeriods')) {
          return;
        }
        if (!this.get('zoomDisabled')) {
          this.send('goTo', period.get('start'), period.get('end'));
        }
        this.get('timeline').set('activePeriod', period);
        period = this.trimEmptyPeriods(period);

        var action = this.get('onEmptyPeriodSelect');
        if (action) {
          return action(period);
        }

        this.sendAction('emptyPeriodSelected', period);
      },
      /**
       * Zoom in to a give time period
       */
      goTo: function goTo(start, end) {
        // find length of period
        var diff = end - start;
        // double it
        diff = diff * 2;
        // exit if we have an invalid period
        if (diff <= 0) {
          Ember.Logger.error('invalid times');
          return;
        }
        // scale the timeline so 2 periods will fit
        var scale = 24 / diff,

        // set offset so there is a 25% offset before the selected period
        offset = start - diff / 4;

        // set scale and offset attributes
        this.set('scale', scale);
        this.set('offset', offset);
      },
      /**
       * Reset scale and offset
       */
      reset: function reset() {
        this.set('scale', 1);
        this.set('offset', 0);
        this.get('timeline').set('activePeriod', null);
      },
      touchStart: function touchStart(type) {
        this.set('touchStatus', type);
      },
      touchEnd: function touchEnd() {
        this.set('touchStatus', false);
      }
    },
    // Methods
    didRender: function didRender() {
      var _this = this;

      var hammertime = new Hammer(this.element);

      hammertime.get('pan').set({
        direction: Hammer.DIRECTION_HORIZONTAL
      });

      hammertime.on('panstart', function (ev) {
        ev.preventDefault();
        _this.panStart(ev);
      });
      hammertime.on('panmove', function (ev) {
        ev.preventDefault();
        _this.panMove(ev);
      });
      hammertime.on('panend', function (ev) {
        ev.preventDefault();
        _this.panEnd(ev);
      });
    },
    panStart: function panStart() /*ev*/{
      if (this.get('panDisabled')) {
        return;
      }
      if (this.get('touchStatus') === false) {
        this.set('panOffset', this.get('offset'));
        this.send('touchStart', 'timeline');
      }
    },
    panMove: function panMove(ev) {
      if (this.get('panDisabled')) {
        return;
      }
      if (this.get('touchStatus') !== 'timeline') {
        return;
      }
      var delta = ev.deltaX;

      var width = this.$().width(),
          ratio = -(325 / this.get('scale')) / width,
          offset = delta * ratio / 12.5 + this.get('panOffset');

      this.set('offset', offset);
    },
    panEnd: function panEnd() /*ev*/{
      if (this.get('panDisabled')) {
        return;
      }
      if (this.get('touchStatus') !== 'timeline') {
        return;
      }
      this.set('panOffset', 0);
      this.send('touchEnd');
    },

    getInverseTimePeriods: function getInverseTimePeriods(periods) {
      var newPeriods = [];

      if (!periods) {
        return [];
      }

      // periods.forEach(p => {
      //   Ember.Logger.log(`Period: ${p.get('start')} = ${p.get('end')}`);
      // })


      var periodCount = Ember.get(periods, 'length');

      if (periodCount === 0) {
        // do we have any periods?
        newPeriods.push(Ember.Object.create({
          start: -1, // NOTE: we add an extra hour for nice alignment
          end: 25
        }));
      } else if (periodCount < 6 /*config.heatingTimeline.maxPeriods*/) {
          //Ember.Logger.log(`Period count ${periodCount}`);
          // always sort periods first
          try {
            periods = periods.sortBy('start');
          } catch (e) {
            return;
          }
          // do we have less than the max number of periods
          var lastStartTime = 0;
          // filter out null values
          periods = periods.filter(function (e) {
            return e;
          });
          // only foreach if there is something there
          if (Ember.get(periods, 'length') > 0) {
            // loop through periods and work out intervals
            periods.forEach(function (period) {
              if (period !== null) if (lastStartTime === period.get('start')) {
                lastStartTime = period.get('end');
              } else {
                if (lastStartTime === 0) {
                  lastStartTime = -1; // NOTE: we add an extra hour for nice alignment
                }

                newPeriods.push(Ember.Object.create({
                  start: lastStartTime,
                  end: period.get('start')
                }));
                lastStartTime = period.get('end');
              }
            });
          }
          // did the last periods end before end of the day?
          if (lastStartTime !== 23.75) {
            newPeriods.push(Ember.Object.create({
              start: lastStartTime,
              end: 25 // NOTE: we add an extra hour for nice alignment
            }));
          }
        }

      // newPeriods.forEach(p => {
      //   Ember.Logger.log(`Empty: ${p.get('start')} = ${p.get('end')}`);
      // });
      // return collection
      return Ember.A(newPeriods);
    },
    /*
     * Empty periods in the timeline can start at -1 and end at 25
     * so they overhang the 24hr timeline. This function will trim
     * periods to 0 - 24 before sending out in actions
     */
    trimEmptyPeriods: function trimEmptyPeriods(period) {
      var trimmed = Ember.Object.create({
        start: period.get('start'),
        end: period.get('end')
      });

      if (trimmed.get('start') < 0) {
        trimmed.set('start', 0);
      }
      if (trimmed.get('end') > 24) {
        trimmed.set('end', 23.75);
      }

      return trimmed;
    }
  });
});