define('ember-select-box/mixins/select-box/option/activatable', ['exports', 'ember-select-box/utils/invoke-action'], function (exports, _invokeAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.set('activeIndex', this.get('-active-index'));
    },


    isActive: Ember.computed('index', 'activeIndex', function () {
      return this.get('index') === this.get('activeIndex');
    }),

    actions: {
      activate: function activate() {
        this._super.apply(this, arguments);
        (0, _invokeAction.default)(this, '-activate', this.get('index'));
      },
      _activate: function _activate() {
        (0, _invokeAction.default)(this, 'on-activate', this.get('value'), this.get('-api'));
      }
    }
  });
});