define('ember-material-lite/components/-base-toplevel-component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Component = Ember.Component;
  exports.default = Component.extend({
    primaryClassName: null,
    _jsClass: true,
    classNameBindings: ['_primaryClassNameString', '_jsClassNameString'],

    _primaryClassNameString: computed('primaryClassName', function () {
      return 'mdl-' + this.get('primaryClassName');
    }),

    _jsClassNameString: computed('primaryClassName', '_jsClass', function () {
      if (this.get('_jsClass')) {
        return 'mdl-js-' + this.get('primaryClassName');
      } else {
        return '';
      }
    })
  });
});