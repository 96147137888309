define('ember-sauce-material-design/components/smd-content', ['exports', 'ember-sauce-material-design/templates/components/smd-content'], function (exports, _smdContent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _smdContent.default,
    classNames: ['mdl-layout__content'],
    tagName: 'main'
  });
});