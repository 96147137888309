define('heating-timeline/components/period-label', ['exports', 'heating-timeline/templates/components/period-label', 'heating-timeline/mixins/transformable-component'], function (exports, _periodLabel, _transformableComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Component = Ember.Component;
  exports.default = Component.extend(_transformableComponent.default, {
    layout: _periodLabel.default,
    // Attributes
    attributeBindings: ['x', 'y', 'height', 'transform'],
    classNames: ['schedule__text'],
    classNameBindings: ['feelsClass'],
    height: 20,
    interval: 12.5,
    yScale: -5,
    model: null, // instance of period model
    offset: 0,
    tagName: 'text',
    scale: 1,
    // Computed
    feelsClass: computed('model.temp', function () {
      if (this.get('model') == null) return;

      var temp = this.get('model.temp'),
          baseClass = 'schedule__text';
      if (temp > 25) {
        return baseClass + '--hot';
      }
      if (temp > 19) {
        return baseClass + '--warm';
      }
      return baseClass + '--cool';
    }),
    width: computed('model.start', 'model.end', 'interval', 'width', function () {
      if (this.get('model') == null) return;

      var interval = this.get('interval');
      return (this.get('model.end') - this.get('model.start')) * interval;
    }),
    x: computed('model.start', 'interval', function () {
      if (this.get('model') == null) return;

      var interval = this.get('interval');
      return this.get('model.start') * interval + this.get('width') / 2;
    }),
    y: computed('model.temp', 'yScale', function () {
      if (this.get('model') == null) return;

      return this.get('model.temp') * this.get('yScale') - 10;
    })
  });
});