define('ember-sauce-material-design/components/smd-app', ['exports', 'ember-sauce-material-design/templates/components/smd-app'], function (exports, _smdApp) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    // Attributes
    layout: _smdApp.default,
    classNames: ['mdl-layout', 'mdl-js-layout'],
    classNameBindings: ['fixedDrawer:mdl-layout--fixed-drawer', 'fixedHeader:mdl-layout--fixed-header', 'noDrawerButton:mdl-layout--no-drawer-button', 'noDesktopDrawerButton:mdl-layout--no-desktop-drawer-button'],
    fixedDrawer: false, // Makes the drawer always visible and open in larger screens
    fixedHeader: false, // Makes the header always visible, even in small screens
    noDrawerButton: false, // Does not display a drawer button
    noDesktopDrawerButton: false // Does not display a drawer button in desktop mode
  });
});