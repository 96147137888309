define('ember-select-box/utils/invoke-action', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = invokeAction;
  function invokeAction(object, name) {
    var action = Ember.get(object, name);

    if (typeof action === 'function') {
      for (var _len = arguments.length, args = Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      action.apply(undefined, args);
    }
  }
});